import NextLink from "next/link";
import { forwardRef } from "react";

const Link = forwardRef(({ onClick, children, className, href, locale, ...props }, ref) => (
  <NextLink {...props} href={href} as={href} locale={locale} passHref>
    <a onClick={onClick} className={className} ref={ref}>
      {children}
    </a>
  </NextLink>
));

export default Link;
