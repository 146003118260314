import { loginWithoutPassword } from "@API/User";
import Toast from "@Components/NEW_TS/common/Toast/index";
import Loading from "@Components/Loading";
import { FormError } from "@Components/LoginView/LoginView.styled";
import { emailPattern } from "@Constants/index";
import { isPlainObject } from "lodash";
import { useRouter } from "next/router";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form6";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { useAuth } from "../../../contexts/AuthContext/Auth.context";
import {
  Btn,
  FormInput,
  Group,
  Inner,
  LoginWrapper,
  Progress,
  ProgressWrapper,
  StepsCounter,
  SubmitBtn,
  SubmitLogin,
  Wrapper
} from "./StepsNavigation.styled";

export default function StepsNavigation({
  nextBtn,
  prevBtn,
  handleQuizSubmit,
  isBankTransfer,
  actionBarVisible,
  currentStep,
  totalSteps,
  paymentHandler,
  lang,
  t,
  isProjectQuiz,
  quizData
}) {
  const { push } = useRouter();
  const { userData, setUserData, updateToken, setLoginModal } = useAuth();
  const isLoggedIn = Boolean(userData?.id);
  const isAnimalStep = !isProjectQuiz ? currentStep === 5 : totalSteps === 16 ? currentStep === 15 : currentStep === 7;
  const [showLogin, setShowLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange"
  });
  const [formError, setFormError] = useState("");

  useEffect(() => {
    setIsLoading(false);
    setFormError("");
    setShowLogin(false);
  }, [isAnimalStep]);

  const submitLogin = formData => {
    setIsLoading(true);
    setFormError("");
    loginWithoutPassword(formData)
      .then(({ data }) => {
        updateToken(data.data.token);
        setUserData(data.data.user);
      })
      .catch(e => {
        setIsLoading(false);
        setFormError(e?.response?.data?.errors?.email[0] || t("something-wrong"));
        Toast({
          type: "success",
          body: e?.response?.data?.errors?.email[0] || t("something-wrong")
        });
        setLoginModal(true);
      });
  };

  useEffect(() => {
    if (isLoggedIn) {
      setIsLoading(false);
      if (nextBtn.route) push(nextBtn.route);
    }
  }, [isLoggedIn]);

  return (
    <Fragment>
      {showLogin && isAnimalStep && (
        <LoginWrapper onSubmit={handleSubmit(submitLogin)}>
          <Container>
            <Row>
              <Col col={12}>
                <Group>
                  <FormInput
                    autoFocus={true}
                    placeholder={t("whats-your-name")}
                    disabled={isLoading}
                    name="name"
                    type="text"
                    hasError={Boolean(errors.name)}
                    ref={register({
                      required: {
                        value: true,
                        message: "required"
                      },
                      pattern: {
                        value: /^[a-z\u0600-\u06FF ]+$/i,
                        message: "invalid-name"
                      }
                    })}
                  />
                </Group>
                <Group>
                  <FormInput
                    autoFocus={true}
                    placeholder={t("whats-your-last-name")}
                    disabled={isLoading}
                    name="last_name"
                    type="text"
                    hasError={Boolean(errors.last_name)}
                    ref={register({
                      required: {
                        value: true,
                        message: "required"
                      },
                      pattern: {
                        value: /^[a-z\u0600-\u06FF ]+$/i,
                        message: "invalid-name"
                      }
                    })}
                  />
                </Group>
                <Group>
                  <FormInput
                    placeholder={t("whats-your-email")}
                    hasError={Boolean(errors.email)}
                    type="text"
                    name="email"
                    disabled={isLoading}
                    ref={register({
                      required: {
                        value: true,
                        message: "required"
                      },
                      pattern: {
                        value: emailPattern,
                        message: "invalid-email"
                      }
                    })}
                  />
                </Group>
                <SubmitLogin type="submit" disabled={isLoading}>
                  {isLoading ? <Loading spinner={true} color="#fff" /> : "Continue"}
                </SubmitLogin>
                {formError ? <FormError>{formError}</FormError> : ""}
              </Col>
            </Row>
          </Container>
        </LoginWrapper>
      )}
      <Wrapper actionBarVisible={actionBarVisible}>
        <ProgressWrapper>
          {[...Array(totalSteps)].map((s, i) => (
            <Progress width={100 / totalSteps} isActive={currentStep >= i + 1} key={"progress-" + i} />
          ))}
        </ProgressWrapper>
        <Container>
          <Row>
            <Col xs={12}>
              <Inner>
                {isPlainObject(prevBtn.route) ? (
                  <Btn disabled={!prevBtn.status} href={prevBtn.route.as || "#"}>
                    {t("prev-step")}
                  </Btn>
                ) : (
                  <Btn disabled={!prevBtn.status} href={prevBtn.route || "#"}>
                    {t("prev-step")}
                  </Btn>
                )}
                <StepsCounter>
                  {currentStep} / {totalSteps}
                </StepsCounter>
                {nextBtn.status === "show-results" ? (
                  <SubmitBtn
                    onClick={isBankTransfer ? paymentHandler : handleQuizSubmit}
                    isActive={nextBtn.status === "show-results" ? 1 : 0}
                    as="button"
                    disabled={isProjectQuiz && !quizData.projectQuizId}
                  >
                    {isBankTransfer
                      ? t("enter-payment-info")
                      : isProjectQuiz
                      ? !quizData.projectQuizId
                        ? "Please wait while uploading photos"
                        : t("show-results")
                      : t("show-results")}
                  </SubmitBtn>
                ) : isAnimalStep && !isLoggedIn ? (
                  <Btn disabled={!nextBtn.status} as="button" onClick={() => setShowLogin(true)}>
                    {t("next-step")}
                  </Btn>
                ) : (
                  <Btn disabled={!nextBtn.status} href={nextBtn.route || "#"}>
                    {t("next-step")}
                  </Btn>
                )}
              </Inner>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Fragment>
  );
}
