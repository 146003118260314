import { StyleQuizSteps } from "@Reducers/QuizForm";
import { RESET_FORM, UPDATE_FORM_STATUS, UPDATE_STEP } from "./types";

export const updateQuizForm = (stepKey, selectedData, completed) => (dispatch, getState) => {
  const quizForm = getState().quizForm;
  const step = quizForm.steps[stepKey];
  const stepsKeys = [...StyleQuizSteps];

  dispatch({
    type: UPDATE_STEP,
    payload: {
      [stepKey]: {
        ...step,
        selected: [...selectedData],
        completed: completed
      }
    }
  });

  if (stepsKeys.pop() === stepKey) {
    dispatch(updateQuizFormStatus(completed));
  }
};

export const setFetchedData = (stepKey, data) => (dispatch, getState) => {
  const quizForm = getState().quizForm;
  const step = quizForm.steps[stepKey];

  dispatch({
    type: UPDATE_STEP,
    payload: {
      [stepKey]: {
        ...step,
        data: data
      }
    }
  });
};

export const updateQuizFormStatus = completed => (dispatch, getState) =>
  dispatch({
    type: UPDATE_FORM_STATUS,
    payload: completed
  });

export const resetForm = () => dispatch => dispatch({ type: RESET_FORM });
