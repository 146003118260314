import { Translate } from "next-translate";
import Image from "next/image";
import Link from "next/link";

import { toast, ToastOptions } from "react-toastify";

import CheckMark from "@Components/NEW_TS/icons/CheckMark";

import {
  CartImage,
  CartToastBody,
  CartToastBodyPrice,
  CartToastButton,
  CartToastWrapper,
  HeaderContainer,
  ToastDesc,
  ToastHeader,
  ToastWrapper
} from "./Toast.styled";

interface Params {
  type: "success" | "error" | "info";
  title?: string;
  body: string;
  options?: ToastOptions;
}
const Toast = ({ type, title, body, options }: Params) => {
  toast.dismiss();

  return (
    <>
      {toast[type](
        <ToastWrapper>
          {title && <ToastHeader type={type}>{title}</ToastHeader>}
          <ToastDesc type={type}>{body}</ToastDesc>
        </ToastWrapper>,
        { ...options, toastId: "customId" }
      )}
    </>
  );
};

export default Toast;

interface CartToastParams {
  t: Translate;
  name?: string;
  type: "cart" | "wishlist";
  quantity?: number;
  price?: number;
  slug?: string;
  options?: Record<any, string | number | boolean | any>;
}
export const CartToast = ({ type, t, name, quantity, price, slug, options }: CartToastParams) => {
  toast.dismiss();
  return (
    <>
      {toast(
        <CartToastWrapper>
          <HeaderContainer>
            <CheckMark />
            <ToastHeader>{type === "cart" ? t("product-added-to-cart") : t("added-to-wishlist")}</ToastHeader>
          </HeaderContainer>
          {type === "cart" && (
            <CartToastBody>
              {name + " " + "( " + quantity + " " + t("item") + " )"}:
              <CartToastBodyPrice>
                {price} {t("egp")}
              </CartToastBodyPrice>
            </CartToastBody>
          )}
          <Link href={type === "cart" ? "/checkout" : Boolean(slug) ? `/favorites/${slug}` : "/login"}>
            <CartToastButton>{type === "cart" ? t("proceed-to-checkout") : t("go-to-wishlist")}</CartToastButton>
          </Link>
        </CartToastWrapper>,
        {
          ...options,
          autoClose: type === "cart" ? 30000 : 5000,
          ...(options.imagePath
            ? {
                icon: (
                  <CartImage className="custom-image">
                    <Image src={options.imagePath} width={83} height={83} objectFit="cover" />
                  </CartImage>
                )
              }
            : {}),
          role: `${type} toast`
        }
      )}
    </>
  );
};
