import { LOCAL_STORAGE_PACKAGE_PROMO_CODE, LOCAL_STORAGE_SHOP_PROMO_CODE } from "@Constants/index";
import { _ValidatePromoCode } from "@Services/Cart";
import { applyCouponForProject } from "@Services/UserProject";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import { IPromoCodeContext, PromoCodeLocalStorageData } from "types/PromoCode";

interface Props {
  children: React.ReactNode;
  promoCodeType?: 1 | 2; // 1 => Shop, 2 => Project
}

const initialState: IPromoCodeContext = {
  isPromoCodeLoading: false,
  isValidPromoCode: null,
  applyPromoCode: () => null,
  resetPromoCode: () => null,
  setIsDownPayment: () => null,
  isDownPayment: false
};

const PromoCodeContext = React.createContext<IPromoCodeContext>(initialState);
PromoCodeContext.displayName = "PromoCode Context"; // Only for debugging

export const usePromoCode = () => useContext(PromoCodeContext);
export const PromoCodeProvider: React.FC<Props> = ({ children, promoCodeType = 1 }) => {
  const [promoCodeData, setPromoCodeData, removePromoCodeData]: [
    PromoCodeLocalStorageData,
    React.Dispatch<PromoCodeLocalStorageData>,
    () => void
  ] = useLocalStorage(promoCodeType === 1 ? LOCAL_STORAGE_SHOP_PROMO_CODE : LOCAL_STORAGE_PACKAGE_PROMO_CODE);
  const [isPromoCodeLoading, setIsPromoCodeLoading] = useState<boolean>(false);
  const [isValidPromoCode, setIsValidPromoCode] = useState<null | boolean>(null); // NULL => Initial State
  const [isDownPayment, setIsDownPayment] = useState(false);

  const validatePromoCode = useCallback((code: string) => {
    setIsPromoCodeLoading(true);
    _ValidatePromoCode({
      coupon_code: code,
      coupon_type: promoCodeType
    })
      .then(res => {
        if (res.data.data.status) {
          setPromoCodeData({
            code,
            percentage: res.data.data.coupon_value
          });
          setIsValidPromoCode(true);
        } else {
          setIsValidPromoCode(null);
          removePromoCodeData();
        }
      })
      .catch(() => {
        setIsValidPromoCode(null);
        removePromoCodeData();
      })
      .finally(() => setIsPromoCodeLoading(false));
  }, []);

  const applyPromoCode = useCallback((code: string, callback?: any, userProjectId?: number) => {
    removePromoCodeData();
    setIsPromoCodeLoading(true);
    setIsValidPromoCode(null);
    _ValidatePromoCode({
      coupon_code: code,
      coupon_type: promoCodeType
    })
      .then(async res => {
        if (res.data.data.status) {
          // ! This will be exist only for Project Cart not shop cart
          if (userProjectId && callback) {
            await applyCouponForProject({
              coupon_code: code,
              user_project_id: userProjectId
            });
            await callback();
            useLocalStorage(LOCAL_STORAGE_PACKAGE_PROMO_CODE)[2]();
          }
          setPromoCodeData({
            code,
            percentage: res.data.data.coupon_value
          });
          setIsValidPromoCode(true);
        } else {
          setIsValidPromoCode(false);
          removePromoCodeData();
        }
      })
      .catch(() => {
        setIsValidPromoCode(false);
      })
      .finally(() => setIsPromoCodeLoading(false));
  }, []);

  const resetPromoCode = useCallback(() => {
    setIsValidPromoCode(null);
    setIsPromoCodeLoading(false);
  }, []);

  useEffect(() => {
    if (promoCodeData) {
      validatePromoCode(promoCodeData.code);
    }
  }, []);

  return (
    <PromoCodeContext.Provider
      value={{
        promoCodeData,
        isPromoCodeLoading,
        applyPromoCode,
        isValidPromoCode,
        resetPromoCode,
        setIsDownPayment,
        isDownPayment
      }}
    >
      {children}
    </PromoCodeContext.Provider>
  );
};
