import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    spaces: {
      sp1: string;
      sp2: string;
      sp3: string;
      sp4: string;
      sp5: string;
      sp6: string;
      sp7: string;
      sp8: string;
    };
    typography: {
      fontFamily: string;
      fontFamilyAr: string;
      htmlBaseFontPx: number;
      baseLineHeight: number;
      baseFontSize: string;
    };
    // Colors
    colors: {
      brandColor: string;
      white: string;
      textColor: string;
      body: {
        background: string;
        contrast: string;
      };
      navbar: {
        divider: string;
      };
      buttons: {
        bgColor: string;
        bgDisabled: string;
      };
      social: {
        facebook: string;
        instagram: string;
        twitter: string;
        whatsapp: string;
      };
      status: {
        error: string;
        success: string;
        warning: string;
      };
      cartItem: {
        comment: string;
      };
      // New Design System Colors
      red100: string;
      red200: string;
      red300: string;
      red400: string;
      red500: string;
      black100: string;
      black200: string;
      black300: string;
      black400: string;
      black500: string;
      black600: string;
    };
    radius: string;
  }
}

const theme: DefaultTheme = {
  spaces: {
    sp1: "4px",
    sp2: "8px",
    sp3: "16px",
    sp4: "24px",
    sp5: "32px",
    sp6: "40px",
    sp7: "48px",
    sp8: "56px"
  },
  // Typography
  typography: {
    fontFamily: "Averta, sans-serif",
    fontFamilyAr: "Tajawal, sans-serif",
    htmlBaseFontPx: 10,
    baseLineHeight: 1.5,
    baseFontSize: "1.6rem"
  },
  // Colors
  colors: {
    brandColor: "#E4001B",
    white: "#fff",
    textColor: "#202020",
    body: {
      background: "#fff",
      contrast: "#fafafa"
    },
    navbar: {
      divider: "#d3d3d3"
    },
    buttons: {
      bgColor: "#e4001b",
      bgDisabled: "#666"
    },
    social: {
      facebook: "#4A65A0",
      instagram: "#e1306c",
      twitter: "#1da1f2",
      whatsapp: "#25d366"
    },
    status: {
      error: "#dc3545",
      success: "#28a745",
      warning: "#ffc107"
    },
    cartItem: {
      comment: "#176CC1"
    },
    // New Design System Colors
    red100: "#e60000",
    red200: "#c40101",
    red300: "#ed4145",
    red400: "#ffe2e2",
    red500: "#fff7f7",
    black100: "#000000",
    black200: "#d9d9d9",
    black300: "#f5f5f5",
    black400: "#fafafa",
    black500: "#333333",
    black600: "#4C4C4C"
  },
  radius: "2px"
};

export default theme;
