import { loginHandler } from "@API/User";
import Loading from "@Components/Loading";
import { emailPattern } from "@Constants/index";
import { useAuth } from "contexts/AuthContext/Auth.context";
import useTranslation from "next-translate/useTranslation";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Div,
  Form,
  FormError,
  FormGroup,
  Input,
  Label,
  LoginWrapper,
  SectionTitle,
  StyledLink,
  Submit
} from "./LoginView.styled";

export default function LoginView({ fromPopup, revalidate }) {
  const { register, handleSubmit, formState } = useForm({
    mode: "onChange"
  });
  const { t } = useTranslation("common");
  const { setUserData, updateToken, setLoginModal } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);

  const handleOnSubmit = data => {
    const { remember, ...formData } = data;
    setIsLoading(true);
    setLoginError(null);
    loginHandler(formData)
      .then(({ data }) => {
        setUserData(data.data.user);
        updateToken(data.data.token);
        setLoginError(null);
        if (fromPopup) {
          setLoginModal(false);
          if (revalidate) revalidate();
        }
      })
      .catch(({ response: { data } }) => {
        setIsLoading(false);
        setLoginError(data.message);
      });
  };

  return (
    <LoginWrapper fromPopup={fromPopup}>
      <SectionTitle>{t("login-slug")}</SectionTitle>
      <Form onSubmit={handleSubmit(handleOnSubmit)}>
        <FormGroup>
          <Label htmlFor={fromPopup ? "email-popup" : "email"}>{t("email-address")}</Label>
          <Input
            placeholder={t("enter-your-email")}
            type="email"
            name="email"
            autoComplete="off"
            id={fromPopup ? "email-popup" : "email"}
            disabled={isLoading}
            {...register("email", {
              required: {
                value: true,
                message: "required"
              },
              pattern: {
                value: emailPattern,
                message: "invalid-email"
              }
            })}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor={fromPopup ? "password-popup" : "password"}>{t("password")}</Label>
          <Input
            placeholder={t("enter-your-password")}
            type="password"
            name="password"
            autoComplete="off"
            id={fromPopup ? "password-popup" : "password"}
            disabled={isLoading}
            {...register("password", {
              required: {
                value: true,
                message: "required"
              },
              minLength: 6
            })}
          />
        </FormGroup>
        {/* <CheckboxGroup>
          <CheckBoxInput
            type="checkbox"
            name="remember"
            id={fromPopup ? "remember-popup" : "remember"}
            ref={register()}
          />
          <CheckBoxLabel htmlFor={fromPopup ? "remember-popup" : "remember"}>
            {t("remember-me")}
          </CheckBoxLabel>
        </CheckboxGroup> */}
        <Submit disabled={!formState.isValid}>
          {isLoading ? <Loading spinner={true} color="#fff" /> : t("login")}
        </Submit>
      </Form>
      {loginError ? <FormError>{loginError}</FormError> : ""}
      <Div fromPopup={fromPopup}>
        <StyledLink href="/register">{t("create-account")}</StyledLink>
        <StyledLink href="/forgot-password">{t("forget-password")}</StyledLink>
      </Div>
    </LoginWrapper>
  );
}
