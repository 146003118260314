const Error = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={57} height={57} viewBox="0 0 57 57">
    <g data-name="Group 79756">
      <g data-name="Ellipse 143" style={{ stroke: "#e4001b", fill: "#fff" }}>
        <circle cx="28.5" cy="28.5" r="28.5" style={{ stroke: "none" }} />
        <circle cx="28.5" cy="28.5" r={28} style={{ fill: "none" }} />
      </g>
      <g data-name="Group 79759">
        <path
          data-name="Line 1892"
          transform="translate(19 19)"
          style={{ strokeWidth: 2, fill: "none", stroke: "#e4001b" }}
          d="M19 0 0 19"
        />
        <path
          data-name="Line 1893"
          transform="translate(19 19)"
          style={{ strokeWidth: 2, fill: "none", stroke: "#e4001b" }}
          d="m0 0 19 19"
        />
      </g>
    </g>
  </svg>
);

export default Error;
