import Link from "@Common/Link";
import Icon from "@Components/common/Icon";
import NumberFormat from "react-number-format";
import styled from "styled-components";

export const LoginPage = styled.section`
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: linear-gradient(90deg, rgba(254, 254, 254, 1) 0%, rgba(254, 254, 254, 0) 100%);
    @media (max-width: 991.98px) {
      width: 30%;
    }
    @media (max-width: 575.98px) {
      display: none;
    }
  }
`;
export const Wrapper = styled.div`
  margin-top: 120px;
  @media (max-width: 1199.98px) {
    margin-top: 0;
  }
`;
export const Banner = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  @media (max-width: 991.98px) {
    width: 30%;
  }
  @media (max-width: 575.98px) {
    display: none;
  }
`;
export const LoginWrapper = styled.div`
  padding: ${({ fromPopup }) => (fromPopup ? "0" : "90px 0 ")};
  max-width: 450px;
  z-index: 1;
  position: relative;
  @media (max-width: 575.98px) {
    padding: ${({ fromPopup }) => (fromPopup ? "0" : "30px 0 ")};
  }
`;
export const SectionTitle = styled.h4`
  font-size: 2.4rem;
  margin-bottom: 30px;
  font-weight: 600;
`;
export const Form = styled.form``;
export const FormGroup = styled.div`
  margin-bottom: 20px;
  position: relative;
`;
export const Label = styled.label`
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.subDesc};
`;
export const Input = styled.input`
  display: block;
  height: 40px;
  width: 100%;
  border: 1px solid ${({ theme, hasError }) => (hasError ? theme.colors.brandColor : theme.colors.gray)};
  padding: 0 15px;
  line-height: 1;
  transition: 0.3s ease box-shadow;
  font-weight: 600;
  &:focus {
    box-shadow: 0 0 10px rgba(80, 80, 80, 0.1);
  }
  &::placeholder {
    font-weight: 400;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.gray};
  }
`;
export const NumInput = styled(NumberFormat)`
  display: block;
  height: 40px;
  width: 100%;
  border: 1px solid ${({ theme, hasError }) => (hasError ? theme.colors.brandColor : theme.colors.gray)};
  padding: 0 15px;
  line-height: 1;
  transition: 0.3s ease box-shadow;
  font-weight: 600;
  &:focus {
    box-shadow: 0 0 10px rgba(80, 80, 80, 0.1);
  }
  &::placeholder {
    font-weight: 400;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.gray};
  }
`;
export const Submit = styled.button`
  appearance: none;
  border: none;
  outline: none;
  display: block;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  height: 45px;
  text-align: center;
  width: 100%;
  margin-top: 30px;
  font-weight: 600;
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray : theme.colors.brandColor)};
  color: ${({ theme, disabled }) => (disabled ? theme.colors.textColor : theme.colors.white)};
  transition: 0.3s ease color, 0.3s ease background-color;
  &:active {
    background-color: ${({ theme, disabled }) => (disabled ? theme.colors.gray : theme.colors.brandColorDimmed)};
  }
`;
export const Div = styled.div`
  display: block;
  text-align: center;
  margin: ${({ fromPopup }) => (fromPopup ? "30px 0 0" : "60px 0 0")};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.textColor};
  &:hover {
    text-decoration: underline;
  }
`;
export const CheckboxGroup = styled(FormGroup)`
  display: flex;
  align-items: center;
`;
export const CheckBoxLabel = styled.label`
  cursor: pointer;
  user-select: none;
  padding-left: 27px;
  white-space: none;
  line-height: 1;
  color: ${({ theme }) => theme.colors.subDesc};
  transition: 0.3s ease color;
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  &::before {
    left: 0;
    top: -1px;
    width: 15px;
    height: 15px;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    transition: 0.3s ease border-color;
  }
  &::after {
    width: 9px;
    height: 9px;
    top: 2px;
    left: 3px;
    background-color: ${({ theme }) => theme.colors.brandColor};
    opacity: 0;
    transition: 0.3s ease opacity, 0.3s ease transform;
    transform: scale(0);
  }
`;
export const CheckBoxInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  &:checked {
    & + ${CheckBoxLabel} {
      color: ${({ theme }) => theme.colors.textColor};
      &::before {
        border-color: ${({ theme }) => theme.colors.brandColor};
      }
      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
`;
export const FormError = styled.p`
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 30px;
  color: ${({ theme }) => theme.colors.brandColor};
`;
export const InputError = styled.p`
  color: ${({ theme }) => theme.colors.brandColor};
  position: absolute;
  bottom: -18px;
  right: 5px;
  line-height: 1;
  font-size: 1.2rem;
  font-weight: 800;
`;
export const SuccessIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.green};
  font-size: 8rem;
  text-align: center;
  display: block;
`;
export const SuccessP = styled.p`
  font-size: 1.9rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.subDesc};
  &:last-of-type {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.textColor};
    font-weight: 600;
    margin-top: 10px;
  }
`;
