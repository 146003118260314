// ! Languages Related Constants
export const SET_LANGUAGE = "SET_LANGUAGE";

// ! User Related Constants
export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";

// ! Login Modal
export const SET_LOGIN_MODAL = "SET_LOGIN_MODAL";

// ! Quiz Form
export const UPDATE_STEP = "UPDATE_STEP";
export const UPDATE_FORM_STATUS = "UPDATE_FORM_STATUS";
export const RESET_FORM = "RESET_FORM";

// ! Project Form
export const P_UPDATE_STEP = "PROJECT_UPDATE_STEP";
export const P_UPDATE_FORM_STATUS = "PROJECT_UPDATE_FORM_STATUS";
export const P_RESET_FORM = "PROJECT_RESET_FORM";
export const SET_PROJECT_ID = "SET_PROJECT_ID";
export const P_UPDATE_TO_THIRD_PACKAGE = "P_UPDATE_TO_THIRD_PACKAGE";
export const P_SET_PAYMENT_SESSION = "P_SET_PAYMENT_SESSION";
export const P_SET_PROJECT_PRICE = "P_SET_PROJECT_PRICE";
export const SET_COUPON_CODE = "SET_COUPON_CODE";

// ! Page Loader
export const SET_PAGE_LOADER = "SET_PAGE_LOADER";
