import Link from "@Common/Link";
import { Submit } from "@Components/LoginView/LoginView.styled";
import { fadeIn } from "@Style/animations";
import { rgba } from "@Utils/style-util";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85px;
  box-shadow: 0 -3px 20px ${rgba("#333", 0.1)};
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 5;
  transition: 0.3s ease transform 0.06s;
  ${({ actionBarVisible }) =>
    actionBarVisible &&
    css`
      transform: translateY(-47px);
    `}
  @media (min-width: 992px) {
    transform: none !important;
  }
  @media (max-width: 767.98px) {
    height: 80px;
  }
  @media (max-width: 575.98px) {
    height: 70px;
  }
`;
export const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Btn = styled(Link)`
  white-space: nowrap;
  font-weight: 600;
  padding: 10px 20px;
  border: 2px solid #333;
  transition: 0.3s ease opacity, 0.3s ease background-color;
  color: #333;
  background-color: #fff;
  text-align: center;
  min-width: 144px;
  cursor: pointer;
  &:active {
    background-color: #eee;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: auto;
      pointer-events: none;
    `}
  ${({ isActive }) =>
    isActive &&
    css`
      color: #fff;
      background-color: ${({ theme }) => theme.colors.brandColor};
      border-color: ${({ theme }) => theme.colors.brandColor};
    `}
  ${({ underlined }) =>
    underlined &&
    css`
      border: 0;
      font-weight: 400;
      text-decoration: underline !important;
    `}
  @media (max-width: 767.98px) {
    font-size: 1.4rem;
    padding: 8px 16px;
  }
  @media (max-width: 575.98px) {
    font-size: 1.4rem;
    padding: 6px 13px;
    border-width: 1px;
    font-weight: 400;
    min-width: 114px;
  }
`;

export const ProgressWrapper = styled.div`
  position: absolute;
  height: 4px;
  border-radius: 4px;
  width: 100%;
  background-color: #fff;
  left: 0;
  top: -1px;
  display: flex;
  align-items: center;
`;
export const Progress = styled.div`
  height: 4px;
  width: ${({ width }) => width}%;
  background-color: #ddd;
  border-right: 4px solid #fff;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    width: ${({ isActive }) => (isActive ? 100 : 0)}%;
    background-color: ${({ theme }) => theme.colors.brandColor};
    transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }
  @media (max-width: 575.98px) {
    border-right: 2px solid #fff;
  }
  &:last-of-type {
    border-right: 0;
  }
`;
export const SubmitBtn = styled(Btn)`
  cursor: pointer;
  @media (max-width: 575.98px) {
    padding: 10px 20px;
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.brandColorDimmed};
  }
`;
export const StepsCounter = styled.p`
  font-size: 1.4rem;
  color: #999;
  user-select: none;
  @media (max-width: 575.98px) {
    font-size: 1.2rem;
  }
`;

export const LoginWrapper = styled.form`
  position: fixed;
  bottom: 60px;
  background-color: #fff;
  left: 0;
  top: 100px;
  width: 100%;
  z-index: 1;
  animation: ${fadeIn} 0.3s ease forwards;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 991.98px) {
    top: 70px;
  }
  @media (max-width: 767.98px) {
    padding: 0;
  }
`;
export const Group = styled.div`
  margin-bottom: 60px;
  @media (max-width: 767.98px) {
    margin-bottom: 30px;
  }
`;
export const FormLabel = styled.label`
  font-size: 2.8rem;
  color: #333;
  font-weight: 600;
`;
export const FormInput = styled.input`
  display: block;
  border: 0;
  outline: 0;
  font-size: 2.8rem;
  color: #333;
  font-weight: 600;
  border-bottom: 2px solid ${({ hasError }) => (hasError ? "red" : "#eee")};
  line-height: 1.5;
  width: 50%;
  margin: auto;
  transition: 0.3s ease border-color;
  @media (max-width: 991.98px) {
    width: 80%;
  }
  @media (max-width: 767.98px) {
    font-size: 2.4rem;
  }
  @media (max-width: 575.98px) {
    font-size: 2rem;
    width: 95%;
  }
`;
export const SubmitLogin = styled(Submit)`
  && {
    width: 50%;
    margin: auto;
    height: 60px;
    @media (max-width: 991.98px) {
      width: 80%;
    }
    @media (max-width: 767.98px) {
      height: 40px;
    }
  }
`;
