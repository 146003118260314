import { Container } from "styled-bootstrap-grid";
import styled from "styled-components";

export const HybridContainer = styled(Container)`
  @media (max-width: 1199.98px) {
    max-width: none;
  }
`;

export const FluidContainer = styled(Container).attrs({
  fluid: true
})`
  padding: 0 64px;
  @media (max-width: 575.98px) {
    padding: 0 20px;
  }
`;
