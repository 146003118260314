import Axios from "lib/Axios";
import { APIResponse } from "types/ApiResponse";
import { ProjectBriefData } from "types/ProjectBrief";
import { ProjectDesignBoardsResponse, ProjectDesignBoard_Meta, ProjectRender } from "types/UserProject";

export const getProjectDesignBoards = (projectId: string | string[]) =>
  Axios.get<APIResponse<ProjectDesignBoardsResponse, ProjectDesignBoard_Meta>>(`/project-quiz-designs/${projectId}`);

export const saveProjectBrief = (data: FormData, userToken: string) =>
  Axios.post<APIResponse<ProjectBriefData, ProjectDesignBoard_Meta>>(`/user/save-project-brief`, data, {
    headers: {
      "content-type": "multipart/form-data",
      ...(userToken
        ? {
            Authorization: "Bearer " + userToken
          }
        : {})
    }
  });

export const updateRoomDesignType = (data: { room_id: number; design_type: number }) =>
  Axios.post("/user/project-brief/update-room-package", data);

export const applyCouponForProject = (data: { user_project_id: number; coupon_code: string }) =>
  Axios.post("/user/project-brief/apply-coupon", data);

export const addNewRoom = (data: { room_id: string | number; design_type: string | number; user_project_id: number }) =>
  Axios.post("/user/project-brief/add-room", data);

export const removeRoom = (data: { room_id: number }) => Axios.post("/user/project-brief/delete-room", data);

export const removeProject = (data: { user_project_id: number }) => Axios.post("/user/project-brief/delete", data);

export const checkoutProject = (data: { user_project_id: number; payment_method: number; coupon_code?: string }) =>
  Axios.post<APIResponse<ProjectBriefData, { iframe_link: string }>>("/user/project-brief/checkout", data);

export const updateProjectPackage = (data: { user_project_id: number; design_type: number }) =>
  Axios.post<APIResponse<ProjectBriefData, { iframe_link: string }>>("/user/project-brief/update-package", data);

export const uploadProjectRoomFiles = (data: FormData) =>
  Axios.post<APIResponse<ProjectBriefData, ProjectDesignBoard_Meta>>(`/user/upload-room-files`, data, {
    headers: {
      "content-type": "multipart/form-data"
    }
  });

export const removeProjectFloorPlan = (data: { file_id: number }) =>
  Axios.post<APIResponse<ProjectBriefData, { iframe_link: string }>>("/user/remove-floor-plan-file", data);

export const removeProjectInspiration = (data: { file_id: number }) =>
  Axios.post<APIResponse<ProjectBriefData, { iframe_link: string }>>("/user/remove-inspiration-photos-file", data);

export const getProjectRenders = (projectId: string | string[]) =>
  Axios.get<APIResponse<ProjectRender[], ProjectDesignBoard_Meta>>(`/project-quiz-renders/${projectId}`);
