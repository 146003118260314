import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  @font-face {
    font-family: 'Averta';
    src: local('☞Averta Bold Italic'), local('Averta-BoldItalic'),
        url('/fonts/Averta-BoldItalic.woff2') format('woff2'),
        url('/fonts/Averta-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
    font-weight: 600;
}
@font-face {
    font-family: 'Averta';
    src: local('☞Averta Bold'), local('Averta-Bold'),
        url('/fonts/Averta-Bold.woff2') format('woff2'),
        url('/fonts/Averta-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    font-weight: 600;
  }
  @font-face {
      font-family: 'Averta';
      src: local('☞Averta Light Italic'), local('Averta-LightItalic'),
          url('/fonts/Averta-LightItalic.woff2') format('woff2'),
          url('/fonts/Averta-LightItalic.woff') format('woff');
      font-weight: 300;
      font-style: italic;
      font-display: swap;
  }
  @font-face {
      font-family: 'Averta';
      src: local('☞Averta Light'), local('Averta-Light'),
          url('/fonts/Averta-Light.woff2') format('woff2'),
          url('/fonts/Averta-Light.woff') format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
  }
  @font-face {
      font-family: 'Averta-RegularItalic';
      src: local('☞Averta Regular Italic'), local('Averta-RegularItalic'),
          url('/fonts/Averta-RegularItalic.woff2') format('woff2'),
          url('/fonts/Averta-RegularItalic.woff') format('woff');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
  }
  @font-face {
      font-family: 'Averta';
      src: local('☞Averta Semibold Italic'), local('Averta-SemiboldItalic'),
          url('/fonts/Averta-SemiboldItalic.woff2') format('woff2'),
          url('/fonts/Averta-SemiboldItalic.woff') format('woff');
      font-weight: 500;
      font-style: italic;
      font-display: swap;
  }
  @font-face {
      font-family: 'Averta';
      src: local('☞Averta'), local('Averta-Regular'),
          url('/fonts/Averta-Regular.woff2') format('woff2'),
          url('/fonts/Averta-Regular.woff') format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }
  @font-face {
      font-family: 'Averta';
      src: local('☞Averta Semibold'), local('Averta-Semibold'),
          url('/fonts/Averta-Semibold.woff2') format('woff2'),
          url('/fonts/Averta-Semibold.woff') format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }
  @font-face {
      font-family: 'Averta';
      src: local('☞Averta Black'), local('Averta-Black'),
          url('/fonts/Averta-Black.woff2') format('woff2'),
          url('/fonts/Averta-Black.woff') format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
  }
`;
