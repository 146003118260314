module.exports = {
  locales: ["en", "ar"],
  defaultLocale: "en",
  pages: {
    "*": ["common"],
    "/contact": ["contact"],
    "/register": ["register"],
    "/login": ["login"],
    "rgx:^/profile/order": ["order"],
    "rgx:^/profile/orders": ["order"],
    "rgx:^/shop": ["product"]
  }
};
