import theAxios from "axios";

const Axios = theAxios.create({
  baseURL: process.env.NEXT_PUBLIC_API,
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
});

export const configureAxiosLocale = (locale: string = "en") => {
  Axios.defaults.headers.common["Content-Language"] = locale;
  Axios.defaults.headers.common["Accept-Language"] = locale;
};

export const configureAxiosAuth = (token?: string) => {
  Axios.defaults.headers.common["Authorization"] = token ? "Bearer " + token : "";
};

export default Axios;
