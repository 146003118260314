import FullPageLoader from "@Components/NEW_TS/common/FullPageLoader";
import { RIA, RINA } from "@Constants/Acl";
import { COOKIE_AUTH, LOGIN_REDIRECT } from "@Constants/index";
import { getUserProfile, logoutFn } from "@Services/User";
import { configureAxiosAuth, configureAxiosLocale } from "lib/Axios";
import { NextSeo } from "next-seo";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useCookie } from "react-use";
import { IAuthContext, UserProfile } from "types/User";

interface Props {
  ACL?: {
    action: typeof RIA | typeof RINA;
  };
}

const initialState: IAuthContext = {
  isUserFetching: false,
  setUserData: () => null,
  logOut: () => null,
  updateToken: () => null,
  loginModal: false,
  setLoginModal: () => null,
  updateCookieRedirect: () => null
};

const UserContext = React.createContext<IAuthContext>(initialState);
UserContext.displayName = "Auth Context"; // Only for debugging

export const useAuth = () => useContext(UserContext);
export const AuthProvider: React.FC<Props> = ({ children, ACL }) => {
  const isClientSide = typeof window !== "undefined" && window.document && window.document.createElement;
  const ACL_Action = ACL?.action;
  const { lang } = useTranslation();
  const [cookieUserToken, setCookieUserToken, removeCookieUserToken] = useCookie(COOKIE_AUTH);
  const [cookieRedirect, updateCookieRedirect, deleteCookieRedirect] = useCookie(LOGIN_REDIRECT);
  const [userData, setUserData] = useState<UserProfile | undefined | null>(
    ACL_Action === "REDIRECT_IF_NOT_AUTHORIZED" ? undefined : null
  );
  const [isUserFetching, setIsUserFetching] = useState<boolean>(Boolean(ACL_Action) && Boolean(cookieUserToken));
  const { replace, asPath, pathname } = useRouter();
  const [loginModal, setLoginModal] = useState(false);

  const updateToken = useCallback(token => {
    setCookieUserToken(token, {
      expires: 30 * 12
    });
    configureAxiosAuth(token);
  }, []);

  const fetchUserData = useCallback(() => {
    setIsUserFetching(true);
    let isUserExist = false;
    getUserProfile(cookieUserToken)
      .then(({ data }) => {
        setUserData(data.data);
        updateToken(cookieUserToken);
        isUserExist = true;
      })
      .catch(err => {
        setUserData(null);
        removeCookieUserToken();
        configureAxiosAuth();
      })
      .finally(() => {
        if (!ACL_Action) {
          setIsUserFetching(false);
        } else {
          if (ACL_Action === RIA && isUserExist) {
            replace("/").then(() => setIsUserFetching(false));
          } else if (ACL_Action === RINA && !isUserExist) {
            replace("/login").then(() => setIsUserFetching(false));
          } else {
            setIsUserFetching(false);
          }
        }
      });
  }, []);

  const logOut = useCallback(
    () =>
      logoutFn()
        .then(() => {
          removeCookieUserToken();
          deleteCookieRedirect();
          configureAxiosAuth();
          setUserData(null);
        })
        .catch(err => window.location.reload()),
    []
  );

  useEffect(() => {
    if (isClientSide) {
      if (cookieUserToken) {
        fetchUserData();
      } else {
        setIsUserFetching(false);
      }
    }
    return () => {
      deleteCookieRedirect();
    };
  }, []);

  useEffect(() => {
    if (cookieUserToken) {
      if (Boolean(userData)) {
        if (cookieRedirect) {
          let redirect = JSON.parse(cookieRedirect);
          replace(redirect.pathname, redirect.asPath).then(() => deleteCookieRedirect());
        } else {
          if (ACL_Action === RIA) {
            replace("/").then(() => setIsUserFetching(false));
          } else {
            setIsUserFetching(false);
          }
        }
      }
    } else {
      if (ACL_Action === RINA)
        replace("/login")
          .then(() => setUserData(null))
          .then(() => {
            const includedRoutes = ["/profile/orders/[id]", "/profile/projects/[id]/overview"].includes(pathname);
            if (includedRoutes) {
              updateCookieRedirect(
                JSON.stringify({
                  asPath: asPath,
                  pathname: pathname
                })
              );
            }
          });
    }
  }, [cookieUserToken, isUserFetching]);

  useEffect(() => {
    configureAxiosLocale(lang);
  }, [lang]);

  if ((isUserFetching && ACL_Action && cookieUserToken) || userData === undefined) {
    return (
      <Fragment>
        <NextSeo titleTemplate="" title="Efreshli | Loading..." />
        <FullPageLoader key={"FULL_PAGE_LOADER"} />
      </Fragment>
    );
  }

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        userToken: cookieUserToken,
        isUserFetching,
        updateToken,
        loginModal,
        setLoginModal,
        logOut,
        updateCookieRedirect
      }}
    >
      <div dir={lang === "ar" ? "rtl" : "ltr"}>{children}</div>
    </UserContext.Provider>
  );
};
