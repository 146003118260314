import dayjs from "dayjs";
import { stripHtml } from "string-strip-html";

export default () => ({
  description:
    "We believe that good design is for everyone and that the furnishing process should be fun. We created Efreshli to provide convenient decoration & furnishing services, all online ",
  facebook: {
    appId: process.env.NEXT_PUBLIC_FB_ID
  },
  openGraph: {
    type: "website",
    locale: "en_IE",
    url: "https://www.efreshli.com/",
    title: "Efreshli",
    site_name: "Efreshli",
    images: [
      {
        url: "https://www.efreshli.com/images/main-logo.png",
        alt: "Efreshli"
      }
    ]
  },
  twitter: {
    handle: "@handle",
    site: "@site",
    cardType: "summary_large_image"
  },
  languageAlternates: [
    { hrefLang: "en-US", href: "https://efreshli.com" },
    { hrefLang: "ar", href: "https://efreshli.com/ar" }
  ]
});

export const generatePageSeo = (
  title,
  locale,
  asPath,
  titleTemplate = "Efreshli - %s",
  description = "Furnishing & decorating a space is exciting but knowing where to start and how to navigate can sometimes seem challenging. We created Efreshli to provide convenient furnishing & decoration services to help create a more accessible journey, all online. Whether you want to be fully involved or need a professional designer to take over, our process is about finding the right mix of products to bring your style to life."
) => ({
  titleTemplate,
  title,
  description,
  languageAlternates: [
    {
      hrefLang: "en",
      href: `https://www.efreshli.com${asPath}`
    },
    {
      hrefLang: "ar",
      href: `https://www.efreshli.com/ar${asPath}`
    }
  ],
  openGraph: {
    description,
    type: "website",
    locale,
    url: `https://www.efreshli.com${asPath}`,
    site_name: "Efreshli",
    images: [
      {
        url: "https://www.efreshli.com/images/main-logo.png",
        alt: "Efreshli"
      }
    ]
  },
  twitter: {
    handle: "@efreshli",
    site: "@efreshli",
    cardType: "summary_large_image"
  }
});

export const blogPostSeo = (data, path) => ({
  title: data.title,
  description: data.description ? stripHtml(data.description).result : "",
  openGraph: {
    title: data.title,
    description: data.description ? stripHtml(data.description).result : "",
    url: process.env.NEXT_PUBLIC_BASE_URL + path,
    type: "article",
    article: {
      publishedTime: dayjs(data.created_at),
      tags: ["Efreshli", "Interior Design", "Interior", "Furniture", "Online Furniture"]
    },
    images: [
      {
        url: data.image_path,
        width: 800,
        height: 600,
        alt: data.title
      }
    ]
  }
});

export const productSeo = (product, path) => ({
  title: product.title,
  canonical: process.env.NEXT_PUBLIC_BASE_URL + path,
  description: product.summary
    ? stripHtml(product.summary).result
    : product.description
    ? stripHtml(product.description).result
    : "",
  openGraph: {
    title: product.title,
    description: product.summary
      ? stripHtml(product.summary).result
      : product.description
      ? stripHtml(product.description).result
      : "",
    url: process.env.NEXT_PUBLIC_BASE_URL + path,
    type: "product",
    images: product.images.length
      ? product.images.map(image => ({
          url: image,
          width: 800,
          height: 600,
          alt: product.title
        }))
      : [
          {
            url: product.image_path,
            width: 800,
            height: 600,
            alt: product.title
          }
        ]
  }
});
