import { css } from "styled-components";

export default css`
  .shop-pagination {
    display: flex;
    justify-content: baseline;
    margin-top: 71px;
    margin-bottom: 88px;
    @media (max-width: 767.98px) {
      margin-top: 35px;
      margin-bottom: 70px;
    }

    li {
      margin-left: 16px;
      font-weight: 600;
      width: 27px;
      height: 27px;
      text-align: center;
      line-height: 29px;

      &:hover {
        cursor: pointer;
      }
      &.selected {
        color: white;
        background-color: black;
        border-radius: 50%;
        vertical-align: middle;
        font-size: 15px;
        font-weight: 600;
      }

      &.disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      a {
        width: 100%;
        height: 100%;
      }
      &.previous,
      &.next {
        width: unset;
        background: transparent;
        position: relative;
      }

      &.previous:before {
        content: "\\e668" /* rtl:"\\e66a" */;
        font-family: "efreshli";
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        position: absolute;
        top: 50%;
        left: -20px;
        transform: translateY(-50%);
        line-height: inherit;
        font-size: 1.4rem;
        color: black;
      }

      &.next:after {
        content: "\\e66a" /*rtl:"\\e668"*/;
        font-family: "efreshli";
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translateY(-50%);
        line-height: inherit;
        font-size: 1.4rem;
        color: black;
      }
    }
  }
`;
