import { AnimatedLogo, Spinner } from "./Loading.styled";

// const Loading = ({ size, color }) => <Spinner size={size} color={color} />
const Loading = ({ size, color, spinner }) =>
  spinner ? (
    <Spinner size={size} color={color} />
  ) : (
    <AnimatedLogo src="/images/efreshli-loading.gif" alt="" size={size} color={color} />
  );

export default Loading;
