import { fadeInUp } from "@Style/animations";
import styled from "styled-components";

export const StepsWrapper = styled.main`
  padding: 130px 0 120px;
  min-height: 100vh;
  @media (max-width: 991.92px) {
    padding: 90px 0 150px;
  }
`;
export const StepTitle = styled.h2`
  font-size: 2.8rem;
  margin: 0 auto 45px;
  text-align: center;
  max-width: 560px;
  z-index: 1;
  position: relative;
  font-weight: 600;
  @media (max-width: 767.98px) {
    font-size: 2.2rem;
    margin: 0 auto 35px;
  }
  @media (max-width: 575.98px) {
    font-size: 2rem;
    margin: 0 auto 25px;
  }
`;
export const StyledInputRangeWrapper = styled.div`
  padding: 0 5px;
  .input-range {
    height: 50px;
  }
  .input-range__label {
    font-size: 1.8rem;
    .input-range__label-container {
      left: auto;
      right: auto;
      display: none;
    }
  }
  .input-range__track {
    height: 10px;
  }
  .input-range__track--active {
    background-color: ${({ theme }) => theme.colors.brandColor};
  }
  .input-range__slider {
    height: 20px;
    width: 20px;
    background-color: rgba(255, 0, 0, 01);
    top: -15px;
    border: none;
    margin: 0;
    margin-left: -3px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
    @media (max-width: 575.98px) {
      height: 15px;
      width: 15px;
      top: -12.5px;
    }
  }
`;

export const SpectrumLabels = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const SpectrumLabel = styled.label`
  font-size: 1.7rem;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? "#333" : "#666")};
  font-weight: ${({ isActive }) => (isActive ? "600" : "400")};
  @media (max-width: 575.98px) {
    font-size: 1.5rem;
  }
`;
export const SpectrumWithHint = styled.div`
  position: relative;
  margin-bottom: 60px;
  p {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
`;
export const SpectrumImage = styled.img`
  object-fit: contain;
  display: block;
  width: 100%;
  height: auto;
  max-height: 300px;
  min-height: 250px;
`;
export const AnswerHint = styled.p`
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  line-height: 1.4;
  padding: 5px 10px;
  font-size: 1.3rem;
  color: #fff;
  max-width: 80%;
`;
export const StepSectionTitle = styled.h4`
  font-size: 1.8rem;
  font-weight: 600;
  & + ${AnswerHint} {
    margin-left: 15px;
  }
`;
export const ColorsWrapper = styled.div`
  display: inline-flex;
  border-radius: 10px;
  overflow: hidden;
  margin: 5px 0 30px;
  animation: ${fadeInUp} ${({ timeAnimation }) => timeAnimation * 0.2}s ease-in-out forwards;
  @media (max-width: 767.98px) {
    width: 100%;
    display: flex;
  }
`;
export const FlexIt = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
`;
export const TitleHintWrapper = styled.div`
  margin-bottom: 45px;
  position: relative;
  ${StepTitle} {
    margin-bottom: 5px;
  }
  ${AnswerHint} {
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
  }
`;

export const QuizResultImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  margin-top: 75px;
  @media (max-width: 575.98px) {
    margin-top: 0;
  }
`;
export const QuizResultImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const QuizResultColorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -75px;
  left: -50px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  @media (max-width: 575.98px) {
    left: auto;
    right: 25px;
    top: 20px;
  }
`;
export const QuizResultColor = styled.div`
  width: 100px;
  height: 50px;
  background-color: ${({ color }) => color};
  @media (max-width: 575.98px) {
    width: 50px;
    height: 25px;
  }
`;
export const QuizResultInfo = styled.div`
  margin-top: 75px;
  @media (max-width: 575.98px) {
    margin-top: 0;
    margin-bottom: 60px;
  }
`;
export const QuizResultHeader = styled.h1`
  font-size: 2.3rem;
  color: red;
  text-align: center;
  margin-bottom: 15px;
  font-weight: 600;
  @media (max-width: 575.98px) {
    font-size: 2.2rem;
  }
`;
export const QuizResultSub = styled.p`
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 300;
  span {
    color: red;
    text-transform: uppercase;
    display: inline-block;
  }
  @media (max-width: 575.98px) {
    font-size: 1.8rem;
  }
`;
export const QuizResultSubTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 7.5px;
  font-weight: 600;
`;
export const StyleDesc = styled.p`
  font-size: 1.6rem;
  color: #666;
  padding-left: 15px;
  font-weight: 400;
  margin-bottom: 30px;
  b {
    font-weight: 400;
    color: red;
    display: block;
    margin-bottom: 3px;
  }
`;
export const QuizResultList = styled.ul`
  list-style: none;
  li {
    margin-bottom: 3px;
    color: #666;
    b {
      font-weight: 400;
      color: red;
    }
  }
`;
export const QuizResultInlineColor = styled.span`
  display: inline-block;
  margin: 0 2px;
  padding: 0 2px;
`;
