import { RESET_FORM, UPDATE_FORM_STATUS, UPDATE_STEP } from "@Actions/types";

// export const StyleQuizSteps = ["rooms", "moods", "furnitures", "brands", "colors"]
export const StyleQuizSteps = [
  "furniture",
  "spectrum",
  "furnitures",
  "colors",
  "animal",
  "rooms",
  "rooms-2",
  "rooms-3",
  "rooms-4",
  "vacation"
];

export const QUIZ_FORM = {
  steps: {
    furniture: {
      completed: false,
      selected: [],
      data: {},
      route: "/style/furniture"
    },
    spectrum: {
      completed: false,
      selected: [],
      data: {},
      route: "/style/spectrum"
    },
    furnitures: {
      completed: false,
      selected: [],
      data: {},
      route: "/style/furnitures"
    },
    colors: {
      completed: false,
      selected: [],
      data: {},
      route: "/style/colors"
    },
    animal: {
      completed: false,
      selected: [],
      data: {},
      route: "/style/animal"
    },
    rooms: {
      completed: false,
      selected: [],
      data: {},
      route: "/style/rooms"
    },
    "rooms-2": {
      completed: false,
      selected: [],
      data: {},
      route: "/style/rooms-2"
    },
    "rooms-3": {
      completed: false,
      selected: [],
      data: {},
      route: "/style/rooms-3"
    },
    "rooms-4": {
      completed: false,
      selected: [],
      data: {},
      route: "/style/rooms-4"
    },
    vacation: {
      completed: false,
      selected: [],
      data: {},
      route: "/style/vacation"
    }
  },
  completed: false
};

export default function QuizFormReducer(currentState = QUIZ_FORM, { type, payload }) {
  switch (type) {
    case UPDATE_STEP: {
      return {
        ...currentState,
        steps: {
          ...currentState.steps,
          ...payload
        }
      };
    }
    case UPDATE_FORM_STATUS: {
      return {
        ...currentState,
        completed: payload
      };
    }
    case RESET_FORM: {
      return QUIZ_FORM;
    }
    default:
      return currentState;
  }
}
