import { resetForm } from "@Actions/QuizAction";
import { saveStyleQuiz } from "@API/User";
import Loading from "@Components/Loading";
import { StepsWrapper } from "@Components/NewQuizComponents/common";
import StepsNavigation from "@Components/NewQuizComponents/StepsNavigation";
import Navbar from "@Components/NEW_TS/Navbar";
import useAnalytics from "@Hooks/useAnalytics.tsx";
import { StyleQuizSteps } from "@Reducers/QuizForm";
import NEW_TS_Theme from "@Style/NEW_TS/Theme";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { ThemeProvider } from "styled-components";
import { withRedux } from "../store";

const QuizLayout = ({ children }) => {
  const { t, lang } = useTranslation("common");
  const quizData = useSelector(state => state.quizForm);
  const quizSteps = quizData.steps;
  const quizStepsKeys = [...StyleQuizSteps];
  const { pathname, replace } = useRouter();

  const currentRouteIsStep = quizStepsKeys.indexOf(pathname.replace("/style/", "").replace("/", "-"));

  let nextBtn, prevBtn;

  if (currentRouteIsStep >= 0) {
    const isCurrentStepCompleted = quizSteps[quizStepsKeys[currentRouteIsStep]].completed;
    const isCurrentFirstStep = quizSteps[quizStepsKeys[0]].route === pathname;
    const isCurrentLastStep = quizSteps[quizStepsKeys[quizStepsKeys.length - 1]].route === pathname;

    nextBtn = {
      status: isCurrentStepCompleted ? (isCurrentLastStep ? "show-results" : "next") : null,
      route: isCurrentStepCompleted
        ? isCurrentLastStep
          ? "/results"
          : quizSteps[quizStepsKeys[currentRouteIsStep + 1]].route
        : false
    };
    prevBtn = {
      status: isCurrentFirstStep ? false : "back",
      route: isCurrentFirstStep ? "" : quizSteps[quizStepsKeys[currentRouteIsStep - 1]].route
    };
  } else {
    nextBtn = {
      status: "restart-quiz",
      route: quizSteps[quizStepsKeys[0]].route
    };
    prevBtn = {
      status: "back",
      route: quizSteps[quizStepsKeys[quizStepsKeys.length - 1]].route
    };
  }

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { logEvent } = useAnalytics();

  const handleQuizSubmit = e => {
    e.preventDefault();
    if (quizData.completed) {
      setIsLoading(true);
      logEvent("View_Style_Quiz_Results", {
        screen_name: "Style Quiz"
      });
      const structuredData = Object.keys(quizData.steps).reduce((acc, stepKey) => {
        const selectedData = quizData.steps[stepKey].selected.map(c => ({
          question_id: +c.question_id,
          answer_id: +c.answer_id
        }));
        return [...acc, ...selectedData];
      }, []);

      saveStyleQuiz({ answers: structuredData })
        .then(({ data }) => {
          if (data.status) {
            replace("/style/[id]", `/style/${data.data.id}`);
            dispatch(resetForm());
          }
        })
        .catch(err => {
          console.log("Error from QuizLayout.js", err);
        });
    }
  };

  // When Unmount -> ResetForm
  useEffect(() => {
    return () => {
      const isStyleQuiz = StyleQuizSteps.some(step => "/style/" + step === pathname);
      if (!isStyleQuiz) {
        setTimeout(() => {
          dispatch(resetForm());
        }, 1000);
      }
    };
  }, []);

  const [actionBarVisible, setActionBarVisible] = useState(false);

  return (
    <Fragment>
      {/* <SwitchLang /> */}
      <StepsWrapper quiz={true}>
        {!isLoading ? (
          children
        ) : (
          <Container>
            <Row justifyContent="center">
              <Col auto>
                <Loading />
              </Col>
            </Row>
          </Container>
        )}
      </StepsWrapper>
      <StepsNavigation
        handleQuizSubmit={handleQuizSubmit}
        nextBtn={nextBtn}
        prevBtn={prevBtn}
        percentage={((currentRouteIsStep + 1) / quizStepsKeys.length) * 100}
        actionBarVisible={actionBarVisible}
        currentStep={currentRouteIsStep + 1}
        totalSteps={quizStepsKeys.length}
        t={t}
        lang={lang}
      />
    </Fragment>
  );
};

export default withRedux(QuizLayout);
