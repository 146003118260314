
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
// import "@formatjs/intl-locale/polyfill-force";
// import "@formatjs/intl-getcanonicallocales/polyfill";
// import "@formatjs/intl-numberformat/locale-data/ar";
// import "@formatjs/intl-numberformat/locale-data/en";
// import "@formatjs/intl-numberformat/polyfill";
import CatchTracking from "@Components/NEW_TS/CatchTracking/index.tsx";
import QuizLayout from "@Containers/QuizLayout";
import { analytics, firebase, logRouterEvent } from "@Services/Analytics.ts";
import * as fbq from "@Services/FacebookPixel.ts";
import { grid } from "@Style/grid";
import "@Style/LiteYouTubeEmbed.css";
import NEW_TS_FONTS from "@Style/NEW_TS/Fonts.ts";
import NEW_TS_GlobalStyle from "@Style/NEW_TS/GlobalStyle.ts";
import NEW_TS_Theme from "@Style/NEW_TS/Theme.ts";
import "@Style/react-toastify.css";
import "@Style/slick-slider.css";
import GlobalStateProvider from "containers/NEW_TS/GlobalStateProvider.tsx";
import { PromoCodeProvider } from "contexts/PromoCode/PromoCode.context";
import dayjs from "dayjs";
import "dayjs/locale/ar";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import firebaseConfig from "firebase.config";
import Axios from "lib/Axios.ts";
import { DefaultSeo } from "next-seo";
import App from "next/app";
import Router from "next/router";
import Script from "next/script";
import NextNprogress from "nextjs-progressbar";
import "rc-rate/assets/index.css"; // Used in Product Details Page [TS]
import { Fragment } from "react";
import { ToastProvider } from "react-toast-notifications";
import { Slide, ToastContainer } from "react-toastify";
import { GridThemeProvider } from "styled-bootstrap-grid";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import stylisRTLCSS from "stylis-rtlcss";
import "swiper/swiper-bundle.min.css"; // Used in Product Details Page [TS]
import { SWRConfig } from "swr";
import { AuthProvider } from "../contexts/AuthContext/Auth.context";
import { CartProvider } from "../contexts/CartContext/Cart.context";
import * as gtag from "../lib/gtag";
import SEO from "../next-seo.config";
import { StyleQuizSteps } from "../store/reducers/QuizForm";
import Error from "./../components/NEW_TS/icons/Error/index";
import Info from "./../components/NEW_TS/icons/Info/index";
import Success from "./../components/NEW_TS/icons/Success/index";

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

Axios.defaults.baseURL = process.env.NEXT_PUBLIC_API;
const swrConfig = {
  fetcher: async query => (await Axios.get(query)).data
};

const FaceBookPixelScript = () => (
  <Script
    strategy="afterInteractive"
    dangerouslySetInnerHTML={{
      __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${fbq.FB_PIXEL_ID});
      `
    }}
  />
);

const GoogleAnalyticsScript = () =>
  process.env.NEXT_PUBLIC_GOOGLE_TAG &&
  process.env.NEXT_PUBLIC_GOOGLE_ADWARDS &&
  process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE ? (
    <Fragment>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`} strategy="afterInteractive" />
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_ADWARDS_ID}`} strategy="afterInteractive" />
      <Script
        src={`https://www.googleoptimize.com/optimize.js?id=${gtag.GOOGLE_OPTIMIZE_ID}`}
        strategy="afterInteractive"
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
            gtag('config', '${gtag.GA_ADWARDS_ID}');
          `
        }}
      />
    </Fragment>
  ) : (
    <Fragment></Fragment>
  );

const HotjarScript = () =>
  process.env.NEXT_PUBLIC_HOTJAR_ID ? (
    <Fragment>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:${process.env.NEXT_PUBLIC_HOTJAR_ID},hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `
        }}
      />
    </Fragment>
  ) : (
    <Fragment></Fragment>
  );

class MyApp extends App {
  routerChangeEventHandler(url) {
    logRouterEvent(this.props.router);
    if (typeof window !== "undefined") {
      fbq.pageview();
      gtag.pageview(url);
    }
  }

  componentDidMount() {
    if (process.env.NEXT_PUBLIC_SENTRY_ENV === "production") {
      if (typeof window !== "undefined" && !firebase.apps.length) {
        fbq.pageview();
        firebase.initializeApp(firebaseConfig);
        window.firebase = firebase;
        analytics().setUserProperties({
          Language: this.props.router.locale === "ar" ? "ar-EG" : "en-US"
        });
      }
      logRouterEvent(this.props.router);
      Router.events.on("routeChangeComplete", this.routerChangeEventHandler.bind(this));
    }
  }

  componentWillUnmount() {
    Router.events.off("routeChangeComplete", this.routerChangeEventHandler.bind(this));
  }

  render() {
    const {
      Component: Page,
      pageProps,
      router: { locale },
      err
    } = this.props;
    const isStyleQuiz = StyleQuizSteps.some(step => "/style/" + step === this.props.router.asPath);
    const PersistentLayout = isStyleQuiz ? QuizLayout : ({ children }) => <>{children}</>;
    const CheckoutPromoContext =
      this.props.router.route === "/checkout" ? PromoCodeProvider : ({ children }) => <>{children}</>;

    return (
      <AuthProvider ACL={pageProps.ACL}>
        <CartProvider>
          <CheckoutPromoContext>
            <Fragment>
              <FaceBookPixelScript />
              <GoogleAnalyticsScript />
              <HotjarScript />
              <CatchTracking />
              <DefaultSeo {...SEO()} />
              <GlobalStateProvider>
                <StyleSheetManager stylisPlugins={locale === "ar" ? [stylisRTLCSS] : []}>
                  <ThemeProvider theme={NEW_TS_Theme}>
                    <GridThemeProvider gridTheme={grid}>
                      <SWRConfig value={swrConfig} key={locale}>
                        <Fragment>
                          <NEW_TS_FONTS />
                          <NEW_TS_GlobalStyle locale={locale} />
                          <NextNprogress height="4" color={NEW_TS_Theme.colors.brandColor} />
                          <ToastProvider
                            autoDismiss={true}
                            autoDismissTimeout={3000}
                            placement={locale === "ar" ? "top-left" : "top-right"}
                          >
                            <PersistentLayout>
                              <Page {...pageProps} err={err} />
                            </PersistentLayout>
                          </ToastProvider>
                        </Fragment>
                        <ToastContainer
                          newestOnTop
                          position={locale === "ar" ? "top-left" : "top-right"}
                          hideProgressBar
                          transition={Slide}
                          autoClose={5000}
                          closeOnClick={false}
                          draggable={false}
                          limit={5}
                          rtl={locale === "ar" ? "ltr" : "ltr"}
                          icon={({ type }) => {
                            return type === "success" ? (
                              <Success />
                            ) : type === "error" ? (
                              <Error />
                            ) : type === "info" ? (
                              <Info />
                            ) : (
                              ""
                            );
                          }}
                        />
                      </SWRConfig>
                    </GridThemeProvider>
                  </ThemeProvider>
                </StyleSheetManager>
              </GlobalStateProvider>
            </Fragment>
          </CheckoutPromoContext>
        </CartProvider>
      </AuthProvider>
    );
  }
}

const __Page_Next_Translate__ = MyApp;


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  