import { EFRESHLI_TRACKING } from "@Constants/index";
import { useRouter } from "next/router";
import React, { Fragment, useEffect, useLayoutEffect } from "react";
import { useLocalStorage } from "react-use";

type Params = {
  utm_content?: string;
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_id?: string;
  utm_term?: string;
};

const arrOfCampaignParams = ["utm_content", "utm_campaign", "utm_medium", "utm_term", "utm_source", "utm_id"];
export default React.memo(function CatchTracking({}) {
  const isClientSide = typeof window !== "undefined" && window.document && window.document.createElement;
  const useIsomorphicEffect = isClientSide ? useLayoutEffect : useEffect;
  const { query } = useRouter();
  const queryParams = query as Params;
  const [value, set, remove] = useLocalStorage(EFRESHLI_TRACKING);

  useIsomorphicEffect(() => {
    if (isClientSide) {
      if (Boolean(queryParams.utm_source) || (document.referrer !== "" && !document.referrer.includes("efreshli.ae"))) {
        const populatedObj = {
          ad_content: queryParams?.utm_content,
          campaign: queryParams?.utm_campaign,
          medium: queryParams?.utm_medium,
          keyword: queryParams?.utm_term,
          source:
            queryParams?.utm_source ||
            (document.referrer.length > 0
              ? document.referrer.includes("efreshli.ae")
                ? "direct"
                : document.referrer
              : "direct"),
          utm_id: queryParams?.utm_id,
          landing_page: document.title
        };
        set(populatedObj);
      }
    }
  }, []);

  return <Fragment></Fragment>;
});

export const getTrackingInfo = () => {
  const [value, set, remove] = useLocalStorage(EFRESHLI_TRACKING);
  return {
    value,
    set,
    remove
  };
};
