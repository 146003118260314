import NextLink from "next/link";
import { forwardRef, Ref } from "react";
import { UrlObject } from "url";

type Props = {
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  children: any;
  className?: string;
  href: string | UrlObject;
  ref: Ref<any>;
  locale?: string;
  title?: string;
  style?: object;
};

const Link = forwardRef(
  ({ onClick, children, className, href, locale, title, style, ...props }: Props, ref: Ref<any>) => (
    <NextLink {...props} href={href} as={href} locale={locale} passHref>
      <a onClick={onClick} className={className} title={title} style={style} ref={ref}>
        {children}
      </a>
    </NextLink>
  )
);

export default Link;
