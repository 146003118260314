import Axios from "lib/Axios";
import { APIResponse, BaseMeta, Pagination } from "types/ApiResponse";
import { ProjectBriefData, ProjectPackage, ProjectRoom } from "types/ProjectBrief";
import {
  ChangePasswordResponse,
  City,
  Governorate,
  Order,
  OrderDetails,
  ProfileDetailsFormFields,
  ProjectTransaction,
  UserAddress,
  UserProfile
} from "types/User";
import { ProjectTransactionDetails } from "types/UserProject";

export const getUserProfile = (token: string) =>
  Axios.get<APIResponse<UserProfile, BaseMeta>>("/user/validate", {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      : {})
  });

export const getUserFavorites = (username: string) => Axios.get(`/favorites/${username}`);

export const getUserAddresses = (token: string) =>
  Axios.get<APIResponse<UserAddress[], BaseMeta>>(`/user/addresses`, {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      : {})
  });

export const getGovernorates = (token: string) =>
  Axios.get<APIResponse<Governorate[], BaseMeta>>(`/user/governorates`, {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      : {})
  });

export const getGovernorateCities = (governorateId: number) =>
  Axios.get<APIResponse<City, BaseMeta>>(`/user/cities/${governorateId}`);

export const addUserAddress = data => Axios.post<APIResponse<UserAddress, BaseMeta>>(`/user/addresses`, data);

export const updateUserAddress = data => Axios.post<APIResponse<UserProfile, BaseMeta>>(`/user/addresses/update`, data);

export const deleteUserAddress = (addressId: number) =>
  Axios.post<APIResponse<UserProfile, BaseMeta>>(`/user/addresses/${addressId}`);

export const updateSelectedAddress = (addressId: number, userToken: string) =>
  Axios.post<APIResponse<UserAddress, BaseMeta>>(`/user/addresses/update-default-address/${addressId}`, {
    headers: {
      Authorization: "Bearer " + userToken
    }
  });

export const getUserOrders = (userToken?: string) =>
  Axios.get<APIResponse<Order[], Pagination>>("/user/orders", {
    ...(userToken
      ? {
          headers: {
            Authorization: "Bearer " + userToken
          }
        }
      : {})
  });

export const getUserProjectsTransations = (userToken?: string) =>
  Axios.get<APIResponse<ProjectTransaction[], BaseMeta>>("/user/project-transactions", {
    ...(userToken
      ? {
          headers: {
            Authorization: "Bearer " + userToken
          }
        }
      : {})
  });

export const getProjectTransation = (id: string | string[], userToken?: string) =>
  Axios.get<APIResponse<ProjectTransactionDetails, BaseMeta>>(`/user/project-transaction-details/${id}`, {
    ...(userToken
      ? {
          headers: {
            Authorization: "Bearer " + userToken
          }
        }
      : {})
  });

export const getOrderDetails = (orderId: string | string[], userToken: string) =>
  Axios.get<APIResponse<OrderDetails, BaseMeta>>(`/user/orders/${orderId}`, {
    headers: {
      Authorization: "Bearer " + userToken
    }
  });

export const updateUserDetails = (data: ProfileDetailsFormFields) =>
  Axios.post<APIResponse<UserProfile, BaseMeta>>("/user/update-profile", data);

export const updateUserPassword = (data: { currentPassword: string; newPassword: string }) =>
  Axios.post<APIResponse<ChangePasswordResponse, BaseMeta>>("/user/change-password", data);

export const setUserPassword = (data: { newPassword: string }) =>
  Axios.post<APIResponse<ChangePasswordResponse, BaseMeta>>("/user/set-password", data);

export const logoutFn = () => Axios.post(`/user/logout`);

export const getUserProjects = (token?: string) =>
  Axios.get<APIResponse<ProjectBriefData[], BaseMeta>>(`/user/list-project-brief`, {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      : {})
  });

export const getUserProjectData = (id: number, token?: string) =>
  Axios.get<APIResponse<ProjectBriefData, BaseMeta>>(`/project-brief-details/${id}`, {
    ...(token
      ? {
          headers: {
            Authorization: "Bearer " + token
          }
        }
      : {})
  });

export const getProjectPackages = (locale: string) =>
  Axios.get<APIResponse<ProjectPackage[], BaseMeta>>("/packages", {
    headers: {
      "Content-Language": locale,
      "Accept-Language": locale
    }
  });

export const getProjectRooms = () => Axios.get<APIResponse<ProjectRoom[], BaseMeta>>("/rooms");

export const ContactFormHandler = (data: { name: string; email: string; message: string }) =>
  Axios.post("/send-message", data);

export const signupHandler = (data: {
  name: string;
  last_name: string;
  email: RegExp;
  password: string;
  confirm_password: string;
}) => Axios.post("/register", data);

export const loginHandler = (data: { email: RegExp; password: string }) => Axios.post("/login", data);

export const socialLoginHandler = (
  data:
    | {
        social_type: string;
        user_id: string;
        id: string;
        email: RegExp | null | undefined | "";
        name: RegExp | null | undefined | "";
      }
    | {
        social_type: string;
        email: RegExp | null | undefined | "";
        id_token: string;
        first_name: string | null | undefined | "";
        last_name: string | null | undefined | "";
      }
) => Axios.post("/social-login", data);
