import { css } from "styled-components";

export default css`
  .rc-pagination {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 30px 0;
    &-item,
    &-prev,
    &-next,
    &-jump-next,
    &-jump-prev {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 30px;
      height: 30px;
      margin-right: 8px;
      line-height: 1;
      text-align: center;
      vertical-align: middle;
      list-style: none;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      outline: 0;
      cursor: pointer;
      user-select: none;
      transition: 0.3s ease color, 0.3s ease border-color;
      &:hover,
      &-active {
        color: #1890ff;
        border-color: #1890ff;
      }
    }
    &-disabled {
      color: #d9d9d9;
      &:hover {
        color: #d9d9d9;
        border-color: #d9d9d9;
        cursor: auto;
      }
    }
  }
`;
