import { analytics, firebase, getScreenName } from "@Services/Analytics";
import { useRouter } from "next/router";

export default function useAnalytics() {
  const { route, asPath } = useRouter();

  const logEvent = (eventName: string, moreOptions?: Record<string, string>) => {
    const nonRouteEvent: boolean = route.startsWith("/style/") || route.startsWith("/project-quiz/");
    if (!getScreenName(route, asPath, nonRouteEvent) && !moreOptions) return;

    if (firebase.apps.length) {
      analytics().logEvent(eventName, {
        screen_name: getScreenName(route, asPath, nonRouteEvent),
        ...moreOptions
      });
    }
  };

  return {
    logEvent
  };
}
