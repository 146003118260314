import React, { ReactElement } from "react";
import styled from "styled-components";

const Full = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: #fff;
  img {
    width: 25vw;
    height: 25vh;
    object-fit: contain;
    max-width: 150px;
  }
`;

export default function FullPageLoader(): ReactElement {
  return (
    <Full>
      <img src="/images/efreshli-loading.gif" alt="Efreshli" />
    </Full>
  );
}
