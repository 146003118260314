import Axios from "lib/Axios.ts";

export const loginHandler = data => Axios.post("/login", data);

export const loginWithoutPassword = data => Axios.post("/register-without-password", data);

export const signupHandler = data => Axios.post("/register", data);

export const forgetHandler = data => Axios.post("/forget-password", data);

export const resetHandler = data => Axios.post("/reset-password", data);

export const toggleFavProduct = data => Axios.post("/user/toggle-fav-product", data);

export const toggleFavProject = data => Axios.post("/user/toggle-fav-project", data);

// export const saveStyleQuiz = data =>
//   Axios.post("/user/save-style-quiz-answers", data, {
//     headers: {
//       "Content-Type": "application/json;charset=UTF-8"
//     }
//   })

export const uploadFloorPlan = (files, projectId) => {
  const formData = new FormData();
  if (files.length > 0) {
    for (let i = 0; i < files.length; i++) {
      formData.append("floorPlanFiles[" + i + "]", Boolean(files[i].file) ? files[i].file : files[i]);
    }
  }

  if (projectId) {
    formData.append("projectQuizId", Number(projectId));
  }

  return Axios.post("/user/upload-floor-plan", formData, {
    headers: {
      "content-type": "multipart/form-data"
    }
  });
};

export const uploadSpacePhotos = (files, projectId, currentPackageRoom) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("spacePhotos[" + i + "]", Boolean(files[i].file) ? files[i].file : files[i]);
  }
  formData.append("projectQuizId", Number(projectId));
  formData.append("currentPackageRoom", currentPackageRoom);

  return Axios.post("/user/upload-space-photos", formData, {
    headers: {
      "content-type": "multipart/form-data"
    }
  });
};

export const uploadInspirations = (files, projectId) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("inspirations[" + i + "]", Boolean(files[i].file) ? files[i].file : files[i]);
  }
  formData.append("user_project_id", Number(projectId));

  return Axios.post("/user/upload-inspirations", formData, {
    headers: {
      "content-type": "multipart/form-data"
    }
  });
};

export const removeFloorPlan = data => {
  return Axios.post("/user/remove-floor-plan-file", data);
};

export const removeSpacePhotos = data => {
  return Axios.post("/user/remove-space-photos-file", data);
};

export const removeInspirations = data => {
  return Axios.post("/user/remove-inspiration-file", data);
};

export const saveProjectData = (
  projectForm,
  currentPackageRoom,
  userMobileNumber,
  sessionVersion = null,
  resultIndicator = null
) => {
  const data = {
    projectQuizAnswers: {
      coupon_code: projectForm.coupon_code,
      userProjectQuizId: projectForm.projectQuizId,
      currentPackageRoom: currentPackageRoom,
      userMobileNumber: userMobileNumber || "",
      selectedPackage: projectForm.steps.packages.data.find(
        p => Number(p.id) === Number(projectForm.steps.packages.selected[0])
      ),
      selectedProjectType: [
        ...projectForm.steps.purpose.data.filter(p => projectForm.steps.purpose.selected.includes(p.id)),
        ...(projectForm.steps.purpose.otherPurpose.length > 0
          ? [
              {
                id: Number(projectForm.steps.purpose.data.length),
                title: projectForm.steps.purpose.otherPurpose
              }
            ]
          : [])
      ],
      selectedBudget: {
        id: 5,
        title: "0"
      },
      selectedRoomItem: (() => {
        const preloadedRooms = projectForm.steps.rooms.data;
        return preloadedRooms.find(
          r =>
            r.id === projectForm.steps[currentPackageRoom === 1 ? "rooms" : `rooms-${currentPackageRoom}`].selected[0]
        );
      })(),
      selectedInspirationItems: (() => {
        const preloadedStyles = projectForm.steps.style.data;

        return preloadedStyles.filter(s =>
          projectForm.steps[currentPackageRoom === 1 ? "style" : `style-${currentPackageRoom}`].selected.includes(s.id)
        );
      })(),
      selectedProducts: (() => {
        const preloadedFurniture = projectForm.steps.furniture.data;

        return preloadedFurniture.filter(f =>
          projectForm.steps[
            currentPackageRoom === 1 ? "furniture" : `furniture-${currentPackageRoom}`
          ].selected.includes(f.id)
        );
      })(),
      selectedPaymentMethod:
        [
          { id: 1, title: "Cash" },
          { id: 2, title: "Visa" }
        ].find(m => m.id === Number(projectForm.steps.payment.selected)) || {},
      floorPlanFile: {
        ...projectForm.steps.plan.selected.map(p => ({}))
      },
      sessionVersion,
      resultIndicator
    }
  };
  return Axios.post("/user/save-project-quiz-answers", data);
};

export const saveProductSelection = data => Axios.post("/user/save-selected-products-sheet", data);

export const saveUserDetails = data => Axios.post("/user/update-profile", data);

export const changePassword = data => Axios.post("/user/change-password", data);

export const setPassword = data => Axios.post("/user/set-password", data);

export const applyPromoCode = code => Axios.get(`/coupon/${code}`);

export const getUserAddresses = () => Axios.get("/user/addresses");

export const getUserOrders = () => Axios.get("/user/orders");

export const addUserAddresses = data => Axios.post("/user/addresses", data);

export const getCities = () => Axios.get("/user/cities");

export const saveStyleQuiz = data => Axios.post("/user/save-new-style-quiz-answers", data);

export const saveDesignBoardItem = data => Axios.post("/user/save-design-board-selected-products", data);
