import React from "react";
import { DotsWrapper, Spinner } from "./LoadingDots.styled";

interface Props {
  color?: string;
  loadingColor?: string;
  className?: string;
  spinner?: boolean;
  size?: number; // to spinner
}

export default function LoadingDots({ color, loadingColor, className, spinner, size }: Props) {
  return spinner ? (
    <Spinner size={size || 30} className={className} />
  ) : (
    <DotsWrapper color={color} loadingColor={loadingColor} className={className} />
  );
}
