type Props = {
  width?: number;
  height?: number;
};

const CheckMark = ({ width, height }: Props) => (
  <svg
    data-name="Group 79539"
    xmlns="http://www.w3.org/2000/svg"
    width={width || 20}
    height={height || 20}
    viewBox="0 0 20 20"
  >
    <circle data-name="Ellipse 143" cx={10} cy={10} r={10} style={{ fill: "#0aa304" }} />
    <path
      data-name="Icon material-done"
      d="M8.516 14.865 5.954 12.3l-.854.854 3.416 3.416 7.319-7.319-.854-.851z"
      transform="translate(-.901 -2.545)"
      style={{ fill: "#fff", stroke: "#fff" }}
    />
  </svg>
);

export default CheckMark;
