/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export const capitalize = (str: string): string =>
  str.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
