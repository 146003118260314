import { stripButton } from "@Utils/style-util";
import styled, { css } from "styled-components";

export const ToastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  @media (min-width: 991.98px) {
    gap: 1.7rem;
  }
`;

export const ToastHeader = styled.h6<{
  readonly type?: string;
}>`
  font-size: 1.3rem;
  font-weight: 600;
  line-height: normal;
  ${({ type }) =>
    type !== "error"
      ? css`
          color: #000;
        `
      : css`
          color: #e4001b;
        `}
  @media (min-width: 991.98px) {
    font-size: 1.5rem;
  }
`;

export const ToastDesc = styled.p<{
  readonly type?: string;
}>`
  font-size: 1.3rem;
  font-weight: 400;
  line-height: normal;
  ${({ type }) =>
    type !== "error"
      ? css`
          color: #777;
        `
      : css`
          color: #e4001b;
        `}
`;

//
//
//
export const CartToastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.15rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
`;

export const CartToastBody = styled.p`
  font-size: 1.3rem;
  color: #000;
  display: flex;
  flex-wrap: wrap;
  gap: 0.9rem;
`;
export const CartToastBodyPrice = styled.span`
  font-size: 1.3rem;
  font-weight: 600;
  color: #f06369;
`;

export const CartToastButton = styled.button`
  ${stripButton};
  cursor: pointer;
  max-width: 22.4rem;
  border-radius: 0.3rem;
  background-color: #000;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
`;

export const CartImage = styled.div<{
  readonly type?: string;
}>`
  height: 100%;
  max-width: 100%;
`;
