import LoginView from "@Components/LoginView";
import useAnalytics from "@Hooks/useAnalytics";
import { useAuth } from "contexts/AuthContext/Auth.context";
import { useCart } from "contexts/CartContext/Cart.context";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useClickAway } from "react-use";
import { RootState } from "store/store";
import { Col, Row } from "styled-bootstrap-grid";
import { FluidContainer } from "../common/CustomGrid";
import Icon from "../common/Icon";
import Link from "../common/Link";
import {
  BurgerMenu,
  CartCount,
  CartSpinner,
  CollapseHeader,
  Cta,
  DropDownLink,
  Inner,
  Li,
  Logo,
  ModalContent,
  NavbarWrapper,
  Navigation,
  NavLink,
  NavLinks,
  ProfileName,
  ResCartLink,
  ResMenuWrapper,
  StyledLink,
  StyledNavLoader,
  StyledRodal,
  StyledThumbnail,
  Ul,
  UserDropdown
} from "./Navbar.styled";

interface Props {}

export default function Navbar({}: Props) {
  const { t } = useTranslation("common");
  const { logEvent } = useAnalytics();
  const { userToken, userData: profile, isUserFetching, setLoginModal, loginModal, logOut } = useAuth();
  const { isCartFetching, totalCartCounter } = useCart();
  const userRef = useRef(null);
  const { isOpened } = useSelector((state: RootState) => state.navSearch);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  useClickAway(userRef, () => setIsDropdownOpen(false));
  const [resCategoryIsOpened, setResCategoryIsOpened] = useState<number>();

  const [resMenu, setResMenu] = useState(false);

  const closeModal = () => setLoginModal(false);

  const logoutHandler = () => {
    logOut().then(() => {
      setResMenu(false);
      setIsDropdownOpen(false);
    });
  };

  useEffect(() => {
    setResCategoryIsOpened(undefined);
  }, [resMenu]);

  const { pathname } = useRouter();

  return (
    <Fragment>
      <NavbarWrapper isOrderDetPage={pathname === "/profile/orders/[id]" && !isDropdownOpen && !isOpened}>
        <FluidContainer>
          <Row>
            <Col col={12}>
              <Inner>
                <BurgerMenu onClick={() => setResMenu(!resMenu)} className={resMenu && "cross"} />
                <Link href="/" style={{ zIndex: 101 }}>
                  <Logo src="/images/logo.svg" alt="Efreshli" />
                </Link>
                <Navigation>
                  <NavLinks>
                    <NavLink href="/pricing">{t("pricing")}</NavLink>
                    <NavLink href="/how">{t("how-it-works")}</NavLink>
                  </NavLinks>
                  <Cta as={Link} href="/new-project">
                    {t("start-your-project")}
                  </Cta>
                  <Ul className="navbar-actions">
                    {isUserFetching ? (
                      <StyledNavLoader uniqueKey="NAVBAR_LOADER">
                        <circle cx={"15"} cy={"30"} r={"10"} />
                        <circle cx={"45"} cy={"30"} r={"10"} />
                        <circle cx={"75"} cy={"30"} r={"10"} />
                      </StyledNavLoader>
                    ) : (
                      <Fragment>
                        {Boolean(profile) ? (
                          <Li ref={userRef}>
                            <StyledLink as="div" title={Boolean(profile) ? profile.name.split(" ")[0] : t("login")}>
                              <div onClick={() => setIsDropdownOpen(prev => !prev)}>
                                <Icon icon="ef_user" />
                                {Boolean(profile) && <ProfileName>{profile.name.split(" ")[0]}</ProfileName>}
                              </div>
                              <UserDropdown dropdownOpen={isDropdownOpen}>
                                <DropDownLink href="/new-project" onClick={() => logEvent("Start_New_Project")}>
                                  {t("start-new-project")}
                                </DropDownLink>
                                <DropDownLink href="/profile/projects">{t("my-projects")}</DropDownLink>
                                <DropDownLink href={`/profile/orders`}>{t("my-orders")}</DropDownLink>
                                <DropDownLink href="/profile">{t("account-details")}</DropDownLink>
                                <DropDownLink as="div" onClick={logoutHandler}>
                                  {t("logout")}
                                </DropDownLink>
                              </UserDropdown>
                            </StyledLink>
                          </Li>
                        ) : (
                          <Li ref={userRef}>
                            <StyledLink as="div">
                              <Icon icon="ef_user" onClick={() => setIsDropdownOpen(prev => !prev)} />
                              <UserDropdown dropdownOpen={isDropdownOpen}>
                                <DropDownLink href="/login">{t("login")}</DropDownLink>
                                <DropDownLink href="/register">{t("register")}</DropDownLink>
                              </UserDropdown>
                            </StyledLink>
                          </Li>
                        )}
                        <Li>
                          <StyledLink title={t("cart")} href={"/checkout"}>
                            <Icon icon="ef_shopping-cart" />
                            {isCartFetching ? (
                              <CartSpinner spinner size={15} />
                            ) : (
                              Boolean(totalCartCounter) && <CartCount>{totalCartCounter}</CartCount>
                            )}
                          </StyledLink>
                        </Li>
                      </Fragment>
                    )}
                  </Ul>
                </Navigation>
                <ResCartLink href="/checkout">
                  <Icon icon="ef_shopping-cart" />
                  {isCartFetching ? (
                    <CartSpinner spinner size={15} />
                  ) : (
                    Boolean(totalCartCounter) && <CartCount>{totalCartCounter}</CartCount>
                  )}
                </ResCartLink>
                <ResMenuWrapper isOpen={resMenu}>
                  {Boolean(profile) ? (
                    <Fragment>
                      <CollapseHeader as={Link} href="/pricing">
                        {t("pricing")}
                      </CollapseHeader>
                      <CollapseHeader as={Link} href={`/how`}>
                        {t("how-it-works")}
                      </CollapseHeader>
                      <CollapseHeader as={Link} href="/profile/projects">
                        {t("my-projects")}
                      </CollapseHeader>
                      <CollapseHeader as={Link} href={`/profile/orders`}>
                        {t("my-orders")}
                      </CollapseHeader>
                      <CollapseHeader as={Link} href="/profile">
                        {t("account-details")}
                      </CollapseHeader>
                      <CollapseHeader onClick={logoutHandler}>{t("logout")}</CollapseHeader>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <CollapseHeader as={Link} href="/pricing">
                        {t("pricing")}
                      </CollapseHeader>
                      <CollapseHeader as={Link} href={`/how`}>
                        {t("how-it-works")}
                      </CollapseHeader>
                      <CollapseHeader as={Link} href={`/login`}>
                        {t("login")}
                      </CollapseHeader>
                      <CollapseHeader as={Link} href={`/register`}>
                        {t("register")}
                      </CollapseHeader>
                    </Fragment>
                  )}
                  <Cta as={Link} href="/new-project">
                    {t("start-your-project")}
                  </Cta>
                </ResMenuWrapper>
              </Inner>
            </Col>
          </Row>
        </FluidContainer>
      </NavbarWrapper>
      {!userToken && (
        <StyledRodal closeOnEsc={true} visible={loginModal} onClose={closeModal}>
          <ModalContent>
            <StyledThumbnail
              src="https://efreshli-uploads.s3.eu-central-1.amazonaws.com/projects/VKHzIGSIHUcupbjMiFSalUkaEdJsSbw1kZPgo9hV.jpeg"
              alt=""
            />
            <LoginView fromPopup={true} revalidate={false} />
          </ModalContent>
        </StyledRodal>
      )}
    </Fragment>
  );
}
