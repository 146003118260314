// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.0
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

// import { Breadcrumbs, GlobalHandlers, LinkedErrors, UserAgent } from "@sentry/browser";

// const client = new BrowserClient({
//   dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
//   environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
//   // Adjust this value in production, or use tracesSampler for greater control
//   tracesSampleRate: 0.0,
//   transport: makeFetchTransport,
//   stackParser: defaultStackParser,
//   // Only the integrations listed here will be used
//   integrations: [new Breadcrumbs(), new GlobalHandlers(), new LinkedErrors(), new UserAgent()]
// });

// getCurrentHub().bindClient(client);
