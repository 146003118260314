import { rgba } from "@Utils/style-util";
import styled from "styled-components";

export const Main = styled.main`
  margin-top: 81px;
  min-height: calc(100vh - 81px);
  @media (max-width: 767.98px) {
    margin-top: 66px;
    min-height: calc(100vh - 66px);
  }
`;

export const CommonBtn = styled.button`
  appearance: none;
  display: block;
  border: none;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.buttons.bgColor};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radius};
  line-height: 1;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: 0.2s ease background;
  &:hover {
    background: ${({ theme }) => rgba(theme.colors.buttons.bgColor, 0.9)};
  }
  &:active {
    background: ${({ theme }) => rgba(theme.colors.buttons.bgColor, 0.95)};
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.buttons.bgDisabled};
    cursor: not-allowed;
  }
`;

export const FixedSuspender = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
`;
