import Icon from "@Components/common/Icon";
import { rgba, stripButton } from "@Utils/style-util";
import ContentLoader from "react-content-loader";
import Rodal from "rodal";
import styled, { css } from "styled-components";
import { CommonBtn } from "../common";
import Link from "../common/Link";
import LoadingDots from "../LoadingDots";

export const NavbarWrapper = styled.header<{
  readonly isOrderDetPage?: boolean;
}>`
  height: 81px;
  display: flex;
  padding: 18px 0 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.navbar.divider};
  background-color: #fff;
  z-index: ${({ isOrderDetPage }) => (isOrderDetPage ? 1 : 11)};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  @media (max-width: 767.98px) {
    height: 66px;
    z-index: 11;
  }
`;
export const Inner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  position: relative;
  z-index: 5;
  @media (max-width: 767.98px) {
    height: auto;
    justify-content: space-between;
  }
`;
export const Logo = styled.img<{ readonly forPrint?: boolean }>`
  display: block;
  object-fit: contain;
  width: 112.65px;
  @media (max-width: 767.98px) {
    width: 100px;
    margin-left: -10px;
  }
  ${({ forPrint }) =>
    forPrint &&
    css`
      display: none;
      @media print {
        display: block;
        margin: 30px auto 0;
        width: 112.65px;
        zoom: 150%;
      }
    `}
`;
export const Navigation = styled.nav`
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 60px;
  @media (max-width: 767.98px) {
    display: none;
  }
`;
export const Li = styled.li`
  margin-right: 30px;
  @media (max-width: 991.98px) {
    margin-right: 20px;
  }
  &:last-of-type {
    margin-right: 0;
  }
`;
export const StyledLink = styled(Link)`
  position: relative;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.textColor};
  text-transform: capitalize;
  cursor: pointer;
  white-space: nowrap;
`;
export const ProfileName = styled.span`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
  line-height: 1 /* rtl:1.3 */;
  overflow: hidden;
  max-width: 50px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Ul = styled.ul`
  display: flex;
  align-items: center;
  margin-right: 35px;
  &.navbar-actions {
    margin-right: 0;
    ${Li} {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      i {
        font-size: 1.8rem;
      }
    }
  }
`;

export const Cta = styled(CommonBtn)`
  && {
    height: 38px;
    padding: 0 30px;
    font-size: 1.3rem;
    text-transform: capitalize;
    margin-right: 30px;
  }
`;
export const StyledNavLoader = styled(ContentLoader)`
  display: block;
  width: 86.72px;
  height: 60px;
`;

export const CartCount = styled.div`
  position: absolute;
  top: -16px;
  right: 0;
  min-width: 15px;
  height: 15px;
  border-radius: 50%;
  padding: 0 2px;
  background-color: #e4001b;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  line-height: 15px;
`;

export const UserDropdown = styled.div<{ readonly dropdownOpen?: boolean }>`
  position: absolute;
  top: 100%;
  left: 50%;
  width: auto;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.4s ease all;
  transform: translate(-50%, 15px);
  z-index: 100;
  border: 1px solid ${({ theme }) => rgba(theme.colors.navbar.divider, 0.15)};
  background: ${({ theme }) => theme.colors.white};
  min-width: 120px;
  ${({ dropdownOpen }) =>
    dropdownOpen &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: translate(-50%, 28px);
    `}
`;
export const DropDownLink = styled(Link)`
  font-size: 1.3rem;
  display: block;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.textColor};
  padding: 10px 15px;
  transition: 0.3s ease color;
  line-height: 1;
  cursor: pointer;
  text-transform: capitalize;
  border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.navbar.divider, 0.15)};
  &:hover {
    color: ${({ theme }) => theme.colors.brandColor};
  }
`;

const Burger = ({ className, onClick }) => (
  <svg className={className} onClick={onClick} viewBox="270 220 400 200">
    <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
    <path d="M300,320 L540,320" id="middle"></path>
    <path
      d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
      id="bottom"
      transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
    ></path>
  </svg>
);
export const BurgerMenu = styled(Burger)`
  display: none;
  @media (max-width: 767.98px) {
    display: block;
  }
  z-index: 101;
  width: 40px;
  height: 30px;
  cursor: pointer;
  -webkit-transform: translate3d(0, 0, 0) scale(0.9);
  -moz-transform: translate3d(0, 0, 0) scale(0.9);
  -o-transform: translate3d(0, 0, 0) scale(0.9);
  -ms-transform: translate3d(0, 0, 0) scale(0.9);
  transform: translate3d(0, 0, 0) scale(0.9);
  path {
    fill: none;
    -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -moz-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -ms-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25), stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 40px;
    stroke-linecap: round;
    stroke: ${({ theme }) => theme.colors.textColor};
    stroke-dashoffset: 0px;
  }
  path#top,
  path#bottom {
    stroke-dasharray: 240px 950px;
  }
  path#middle {
    stroke-dasharray: 240px 240px;
  }
  &.cross path#top,
  &.cross path#bottom {
    stroke-dashoffset: -650px;
    stroke-dashoffset: -650px;
  }
  &.cross path#middle {
    stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px;
  }
  &.cross path {
    stroke: ${({ theme }) => rgba(theme.colors.textColor, 0.7)};
  }
`;
export const ResMenuWrapper = styled.div<{ readonly isOpen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 100;
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transition: 0.4s ease opacity, 0.4s ease visibility;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: calc(100% - 59px);
  max-height: calc(100% - 59px);
  overflow-y: auto;
  padding-bottom: 25px;
  @media (min-width: 768px) {
    display: none;
  }
  ${Cta} {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 23px;
    min-height: 56px;
    font-size: 1.6rem;
    font-weight: 500;
  }
`;
export const PrintLogoLink = styled.a`
  display: none;
  @media print {
    display: block;
    text-align: center;
  }
`;

export const StyledRodal = styled(Rodal)`
  .rodal-dialog {
    width: 100% !important;
    height: 100% !important;
    max-height: 500px;
    max-width: 900px;
    padding: 0;
    overflow: hidden;
    @media (max-width: 991.98px) {
      max-width: 90%;
    }
    @media (max-width: 575.98px) {
      min-height: max-content;
      max-height: 95%;
      overflow-y: scroll;
      height: auto !important;
      padding: 30px 15px;
    }
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  @media (max-width: 575.98px) {
    flex-direction: column;
  }
  & > div {
    width: calc(50% - 60px);
    @media (max-width: 767.98px) {
      width: calc(65% - 60px);
    }
    @media (max-width: 575.98px) {
      width: 100%;
    }
  }
`;
export const StyledThumbnail = styled.img`
  display: block;
  height: 100%;
  width: 50%;
  object-fit: cover;
  margin-right: 30px;
  @media (max-width: 767.98px) {
    width: 35%;
  }
  @media (max-width: 575.98px) {
    display: none;
  }
`;

export const CartSpinner = styled(LoadingDots)`
  && {
    position: absolute;
    top: -20px;
    right: -2px;
    border-width: 2px;
  }
`;
export const ResCartLink = styled(Link)`
  display: block;
  font-size: 2.2rem;
  position: relative;
  color: #000;
  z-index: 101;
  height: 30px;
  & > div {
    top: -10px !important;
  }
  @media (min-width: 767.98px) {
    display: none;
  }
`;
export const NavSearchInputWrapper = styled.form`
  flex: 1;
  margin-right: 45px;
  position: relative;
  @media (max-width: 767.98px) {
    margin-right: 0;
    margin-top: 15px;
  }
`;
export const StyledInput = styled.input`
  display: block;
  width: 100%;
  height: 45px;
  border-radius: 24px;
  border: solid 0.5px #d3d3d3;
  background-color: #fff;
  padding: 15px 18px 14.8px 45px;
  transition: 0.2s ease border-color;
  &:focus {
    border: solid 0.5px ${({ theme }) => theme.colors.buttons.bgColor};
  }
  @media (max-width: 767.98px) {
    border-radius: 5px;
  }
`;
export const InnerCategories = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: auto;
`;
export const CategoryWrapper = styled.div`
  position: relative;
`;
export const CategoryLink = styled(Link)<{ readonly isActive?: boolean }>`
  font-size: 1.4rem;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  background-color: #fff;
  transition: 0.3s ease color, 0.3s ease background-color;
  line-height: 1;
  border-radius: 14px;
  color: #202020;
  margin-bottom: 11px;
  white-space: nowrap;
  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.colors.brandColor};
      background-color: #f8f8f8;
    `}
`;
export const CategoryDropdown = styled.div<{ readonly dropdownOpen?: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  width: auto;
  min-width: 215px;
  background-color: red;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.4s ease all;
  transform: translateY(10px);
  z-index: 100;
  border: 1px solid ${rgba("#707070", 0.2)};
  border-radius: 5px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.04);
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  ${({ dropdownOpen }) =>
    dropdownOpen &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: translateY(-4px);
    `}
`;
export const NavSubLink = styled(Link)`
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  padding: 20px;
  border-bottom: 1px solid ${rgba("#707070", 0.04)};
  background-color: #fff;
  transition: 0.3s ease background-color;
  color: #000;
  &:hover {
    background-color: #fafafa;
  }
`;
export const ResNavSearchInput = styled.div`
  display: none;
  @media (max-width: 767.98px) {
    display: block;
  }
`;
export const CategoryCollapseContent = styled.div``;
export const CategoryCollapseLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 21px 0 38px;
  font-size: 1.5rem;
  font-weight: 500;
  min-height: 50px;
  max-height: 50px;
  color: #000;
  border-bottom: 1px solid ${rgba("#707070", 0.1)};
  span {
    opacity: 0.36;
    font-weight: 400;
  }
`;
export const CollapseHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  max-height: 60px;
  padding: 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  border-bottom: 1px solid ${rgba("#707070", 0.1)};
  color: #000;
  font-weight: 600;
  white-space: nowrap;
`;
export const CollapseLink = styled(Link)<{ readonly isActive?: boolean }>`
  color: ${({ isActive, theme }) => (isActive ? theme.colors.buttons.bgColor : "#000")};
  font-size: 1.6rem;
  font-weight: ${({ isActive }) => (isActive ? "500" : "400")};
  transition: 0.3s ease color;
`;
export const CollapsibleCategories = styled.div`
  border-bottom: 16px solid #f8f8f8;
`;
export const CatCollapseArrow = styled(Icon)<{ readonly isOpened?: boolean }>`
  transform: ${({ isOpened }) => (isOpened ? "rotate(-180deg)" : "none")};
  transition: 0.4s ease transform;
  line-height: 1;
`;
export const NavLinks = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 4;
  margin-left: auto;
  margin-right: 30px;
  @media (max-width: 767.98px) {
    display: none;
  }
`;
export const NavLink = styled(Link)`
  font-size: 1.3rem;
  color: #000;
  font-weight: 500;
  margin-right: 27px;
  white-space: nowrap;
  &:last-of-type {
    margin-right: 0;
  }
`;
export const SearchDropdown = styled.div<{ readonly dropdownOpen?: boolean }>`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.4s ease all;
  transform: translateY(15px);
  z-index: 100;
  border: 1px solid ${rgba("#707070", 0.2)};
  border-radius: 5px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.04);
  background: ${({ theme }) => theme.colors.white};
  max-height: 291px;
  overflow: auto;
  @media (min-width: 767.98px) {
    max-height: unset;
    overflow: hidden;
  }
  ${({ dropdownOpen }) =>
    dropdownOpen &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: translateY(7px);
    `}
`;
export const SearchResultLink = styled(Link)`
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 20px;
  border-bottom: 1px solid ${rgba("#707070", 0.2)};
  color: #000;
  transition: 0.2s ease background-color;
  background-color: #fff;
  &:hover {
    background-color: #fafafa;
  }
  .bold {
    font-weight: 600;
  }
  & * {
    pointer-events: none;
  }
`;
export const SubmitSearch = styled.button`
  ${stripButton};
  position: absolute;
  top: 14px;
  left: 18px;
`;
export const ClearSearch = styled.button`
  ${stripButton};
  position: absolute;
  top: 14px;
  right: 18px;
  img {
    width: 10px;
  }
`;
export const Overlay = styled.div<{ readonly isActive: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.3s ease opacity, 0.3s ease visibility;
  transition-delay: 0.1s, 0.1s;
  z-index: 10;
  ${({ isActive }) =>
    isActive &&
    css`
      transition-delay: 0s, 0s;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    `}
`;
