import { dotFlashing, rotateSpinner } from "@Style/animations";
import styled from "styled-components";

export const Spinner = styled.div<{
  readonly size?: number;
}>`
  min-height: ${({ size }) => (size ? size : "30")}px;
  min-width: ${({ size }) => (size ? size : "30")}px;
  max-height: ${({ size }) => (size ? size : "30")}px;
  max-width: ${({ size }) => (size ? size : "30")}px;
  color: #333;
  display: inline-block;
  border: 4px solid #ddd;
  border-radius: 50%;
  border-right-color: #333;
  animation: ${rotateSpinner} 1s linear infinite;
`;

export const DotsWrapper = styled.div<{ readonly color?: string; readonly loadingColor?: string }>`
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: ${({ theme, color }) => (color ? color : theme.colors.white)};
  color: ${({ theme, color }) => (color ? color : theme.colors.white)};
  animation: ${({ color, loadingColor }) => dotFlashing(color, loadingColor)} 0.6s infinite linear alternate;
  animation-delay: 0.3s;
  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  &::before {
    left: -13px;
    background-color: ${({ theme, color }) => (color ? color : theme.colors.white)};
    color: ${({ theme, color }) => (color ? color : theme.colors.white)};
    animation: ${({ color, loadingColor }) => dotFlashing(color, loadingColor)} 0.6s infinite alternate;
    animation-delay: 0s;
  }
  &::after {
    left: 13px;
    background-color: ${({ theme, color }) => (color ? color : theme.colors.white)};
    color: ${({ theme, color }) => (color ? color : theme.colors.white)};
    animation: ${({ color, loadingColor }) => dotFlashing(color, loadingColor)} 0.6s infinite alternate;
    animation-delay: 0.6s;
  }
`;
