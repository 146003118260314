import React from "react";
import styled from "styled-components";

const I = styled.i`
  font-size: inherit;
  outline: none;
`;

export default function Icon({ icon, className, ...props }: { icon: string; className?: string; onClick?: any }) {
  return <I tabIndex={-1} className={`efreshli icon-${icon} ${className || ""}`} {...props} />;
}
