import { rotateSpinner } from "@Style/animations.js";
import { rgba } from "@Utils/style-util";
import styled from "styled-components";

export const Spinner = styled.div`
  min-height: ${({ size }) => (size ? size : "30")}px;
  min-width: ${({ size }) => (size ? size : "30")}px;
  max-height: ${({ size }) => (size ? size : "30")}px;
  max-width: ${({ size }) => (size ? size : "30")}px;
  color: ${({ theme, color }) => (color ? color : theme.colors.brandColor)};
  display: inline-block;
  border: 4px solid ${({ theme, color }) => rgba(color || theme.colors.brandColor, 0.2)};
  border-radius: 50%;
  border-right-color: ${({ theme, color }) => color || theme.colors.brandColor};
  animation: ${rotateSpinner} 1s linear infinite;
`;
export const AnimatedLogo = styled.img`
  min-height: ${({ size }) => (size ? size : "90")}px;
  min-width: ${({ size }) => (size ? size : "90")}px;
  max-height: ${({ size }) => (size ? size : "90")}px;
  max-width: ${({ size }) => (size ? size : "90")}px;
  display: inline-block;
  object-fit: contain;
`;
