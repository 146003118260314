import React from "react";
import { Provider } from "react-redux";
import { store } from "store/store";

type Props = {
  children: JSX.Element;
};

const ProviderInner = ({ children }: Props) => {
  return children;
};

export default function GlobalStateProvider({ children }: Props) {
  return (
    <Provider store={store}>
      <ProviderInner>{children}</ProviderInner>
    </Provider>
  );
}
