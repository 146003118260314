import { createModel } from "@rematch/core";
import { RootModel } from ".";

type CategoriesState = {
  searchQuery: string;
  searchResults: any[];
  isOpened: boolean;
};

const categories = createModel<RootModel>()({
  name: "navSearch",
  state: {
    searchQuery: "",
    searchResults: [],
    isOpened: false
  } as CategoriesState,
  reducers: {
    setSearchResults(state, payload: any[]) {
      state.searchResults = payload;
    },
    setSearchQuery(state, payload: string) {
      state.searchQuery = payload;
    },
    setIsOpened(state, payload: boolean) {
      if (payload) {
        state.isOpened = payload;
      } else {
        state.isOpened = payload;
      }
    }
  },
  effects: dispatch => ({
    async getSearchResults(query: string) {
      dispatch.NavSearch.setSearchQuery(query);
    }
  })
});

export default categories;
