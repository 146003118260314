import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import paginationCSS from "../paginationCSS";
import shopPaginatorCSS from "../shopPaginatorCSS";
import rodalCss from "../rodalCss";

export default createGlobalStyle<{ readonly locale?: string }>`
  ${normalize}
  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    font-size: ${({ theme }) => theme.typography.htmlBaseFontPx}px;
  }

  body {
    font-size: 1.6rem;
    line-height: ${({ theme }) => theme.typography.baseLineHeight};
    position: relative;
    cursor: default;
    font-weight: normal;
    font-family: ${({ theme, locale }) =>
      locale === "ar" ? theme.typography.fontFamilyAr : theme.typography.fontFamily};
    background-color: ${({ theme }) => theme.colors.body.background};
    color: ${({ theme }) => theme.colors.textColor};
  }

  @media print {
    html, body {
      width: 210mm; 
      height: 280mm;
      -webkit-print-color-adjust: exact;
    }
  }

  [hidden] {
    display: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  a,
  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
    outline: none;
  }

  button,
  button:focus {
    outline: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }

  .en {
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  .ar {
    font-family: ${({ theme }) => theme.typography.fontFamilyAr};
  }

  ${rodalCss}
  ${paginationCSS}
  ${shopPaginatorCSS}

  .ReactCollapse--collapse {
    transition: height 400ms;
  }
  .page-enter {
    opacity: 0;
  }

  .page-enter-active {
    opacity: 1;
    transition: opacity 300ms
  }

  .page-exit {
    opacity: 1;
  }

  .page-exit-active {
    opacity: 0;
    transition: opacity 300ms
  }
`;
