import { CartToast } from "@Components/NEW_TS/common/Toast/index";
import { LOCAL_STORAGE_REFERRER } from "@Constants/index";
import { _AddToCart, _DeleteProductCart, _GetCartList, _UpdateCartOnceAuth, _UpdateProductCart } from "@Services/Cart";
import { useAuth } from "contexts/AuthContext/Auth.context";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "react-use";
import { AddToCartData, CartList, ICartContext } from "types/Cart";

const initialState: ICartContext = {
  isCartFetching: false,
  fetchCartList: () => null,
  addToCartList: () => null,
  updateProductInCart: () => null,
  removeProductFromCart: () => null
};

const CartContext = React.createContext<ICartContext>(initialState);
CartContext.displayName = "Cart Context"; // Only for debugging

export const useCart = () => useContext(CartContext);
export const CartProvider: React.FC = ({ children }) => {
  const { userToken } = useAuth();
  const isClientSide = typeof window !== "undefined" && window.document && window.document.createElement;
  const { route } = useRouter();
  const [, setReferrer] = useLocalStorage(LOCAL_STORAGE_REFERRER);
  const [cartTempId, setCartTempId, removeCartTempId]: [string, React.Dispatch<string>, () => void] =
    useLocalStorage("cart_temp_id");
  // Default State is null is a flag used for defining that the cart is in the initial state, if Cart empty it should be undefined
  const [cartData, setCartData] = useState<CartList | undefined | null>(null);
  const [isCartFetching, setIsCartFetching] = useState<boolean>(false);
  const cartProductsNumber = useMemo(
    () => (Boolean(cartData?.total) ? cartData.cart.reduce((acc, curr) => acc + curr.quantity, 0) : 0),
    [cartData]
  );
  const totalCartCounter = useMemo(
    () =>
      Boolean(cartData?.rooms?.length)
        ? cartData.rooms.reduce(
            (acc, curr) => acc + curr.products.reduce((ac, cur) => ac + cur.quantity, 0),
            cartProductsNumber
          ) || 0
        : cartProductsNumber,
    [cartProductsNumber, cartData]
  );

  const { t } = useTranslation("common");
  const addToast = (data, products: AddToCartData[]) => {
    let addedProduct;
    if (products[0].design_board_id !== undefined && products[0].design_board_id !== null) {
      data.data.rooms.find(room =>
        room.products.find(p => {
          addedProduct =
            p.design_board_id === products[0].design_board_id && p.variation_id === products[0].product_id && p;
        })
      );
    } else {
      addedProduct = data.data.cart.find(p => p.variation_id === products[0].product_id);
    }

    CartToast({
      type: "cart",
      t,
      name: t(addedProduct.title),
      quantity: addedProduct.quantity,
      price: addedProduct.price * addedProduct.quantity,
      options: {
        imagePath: addedProduct.image_path
      }
    });
  };
  const fetchCartList = async (
    _cartTempId?: string,
    withoutLoading?: boolean,
    products?: AddToCartData[],
    isPaused?: boolean
  ) => {
    if (!withoutLoading) {
      setIsCartFetching(true);
    }
    if (userToken) {
      if (_cartTempId || cartTempId) {
        try {
          await _UpdateCartOnceAuth({
            cart_temp_id: _cartTempId || cartTempId
          });
        } catch (error) {
          removeCartTempId();
        }
        removeCartTempId();
      }
      try {
        const { data } = await _GetCartList({ userToken });
        setCartData(data.data);
        if (products && !isPaused) {
          addToast(data, products);
        }
      } catch (error) {
        setCartData(undefined);
      }
    } else {
      if (cartTempId || _cartTempId) {
        try {
          const { data } = await _GetCartList({ cart_temp_id: cartTempId || _cartTempId });
          setCartData(data.data);
          setCartTempId(cartTempId || _cartTempId);

          if (products && !isPaused) {
            addToast(data, products);
          }
        } catch (error) {
          setCartData(undefined);
          removeCartTempId();
        }
      } else {
        setCartData(undefined);
      }
    }
    setIsCartFetching(false);
  };

  const addToCartList = ({ products }: { products: AddToCartData[] }, isPaused) => {
    return _AddToCart({
      products,
      cart_temp_id: cartTempId,
      userToken
    })
      .then(response => {
        setCartTempId(response.data.meta.cart_temp_id);
        fetchCartList(response.data.meta.cart_temp_id, false, products, isPaused);

        return response;
      })
      .catch(err => {
        removeCartTempId();
        throw err;
      });
  };

  const updateProductInCart = ({
    cart_id,
    quantity,
    user_notes,
    withoutLoading
  }: {
    cart_id: number;
    quantity: number;
    user_notes: string;
    withoutLoading?: boolean;
  }) => {
    return _UpdateProductCart(
      {
        cart_id,
        quantity,
        user_notes
      },
      cartTempId,
      userToken
    )
      .then(response => {
        fetchCartList(response.data.meta.cart_temp_id, withoutLoading);
        return response;
      })
      .catch(err => {
        removeCartTempId();
        throw err;
      });
  };

  const removeProductFromCart = (cart_id: number, withoutLoading?: boolean) => {
    return _DeleteProductCart(cart_id, cartTempId, userToken)
      .then(response => {
        fetchCartList(response.data.meta.cart_temp_id, withoutLoading);
        return response;
      })
      .catch(err => {
        removeCartTempId();
        throw err;
      });
  };

  useEffect(() => {
    if (route !== "/pay-confirm") {
      setReferrer(document.referrer);
    }
  }, []);

  useEffect(() => {
    if (isClientSide) {
      fetchCartList();
    }
  }, [userToken]);

  useEffect(() => {
    if (cartData !== null && totalCartCounter === 0) {
      removeCartTempId();
    }
  }, [totalCartCounter]);

  return (
    <CartContext.Provider
      value={{
        cartData,
        isCartFetching,
        totalCartCounter,
        fetchCartList,
        addToCartList,
        updateProductInCart,
        removeProductFromCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
