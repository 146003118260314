const Success = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={57} height={57} viewBox="0 0 57 57">
    <g data-name="Group 79756">
      <g data-name="Ellipse 143" style={{ fill: "#fff", stroke: "#0aa304" }}>
        <circle cx="28.5" cy="28.5" r="28.5" style={{ stroke: "none" }} />
        <circle cx="28.5" cy="28.5" r={28} style={{ fill: "none" }} />
      </g>
      <path
        data-name="Icon material-done"
        d="M14.812 26.784 7.528 19.5 5.1 21.928l9.712 9.712 20.812-20.812L33.2 8.4z"
        transform="translate(8.4 8.1)"
        style={{ fill: "#0aa304", stroke: "#fff" }}
      />
    </g>
  </svg>
);

export default Success;
