import Axios from "lib/Axios";
import { APIResponse, BaseMeta } from "types/ApiResponse";
import { AddToCartData, CartList, CartMeta } from "types/Cart";
import { CouponData, CouponResData } from "types/PromoCode";
import { VisaSuccessMeta, VisaSuccessResponse } from "types/Shop";
import { OrderDetails, UserAddress } from "types/User";

// ! New APIs
export const _GetCartList = ({ userToken, cart_temp_id }: { userToken?: string; cart_temp_id?: string }) =>
  Axios.get<APIResponse<CartList, CartMeta>>("/user/list-cart", {
    headers: {
      Authorization: "Bearer " + userToken
    },
    ...(cart_temp_id && {
      params: {
        cart_temp_id
      }
    })
  });

export const _AddToCart = ({
  products,
  cart_temp_id,
  userToken
}: {
  products: AddToCartData[];
  cart_temp_id?: string;
  userToken?: string;
}) =>
  Axios.post<APIResponse<[], CartMeta>>(
    `/user/add-to-cart`,
    {
      products,
      cart_temp_id
    },
    {
      ...(userToken
        ? {
            headers: {
              Authorization: "Bearer " + userToken
            }
          }
        : {})
    }
  );

export const _UpdateCartOnceAuth = ({ cart_temp_id }: { cart_temp_id?: string }) =>
  Axios.post<APIResponse<[], CartMeta>>(`/user/update-cart-user`, {
    cart_temp_id
  });

export const _UpdateProductCart = (
  data: { cart_id: number; quantity?: number; user_notes: string },
  cart_temp_id?: string,
  userToken?: string
) =>
  Axios.post("/user/update-cart", data, {
    headers: {
      Authorization: "Bearer " + userToken
    },
    params: cart_temp_id && {
      cart_temp_id
    }
  });

export const _DeleteProductCart = (cart_id: number, cart_temp_id?: string, userToken?: string) =>
  Axios.get(`/user/remove-from-cart/${cart_id}`, {
    headers: {
      Authorization: "Bearer " + userToken
    },
    params: cart_temp_id && {
      cart_temp_id
    }
  });

// ! Old APIs
export const addToCartFn = (
  productId: number,
  productOptions?: {
    variation_id?: number;
    design_board_id?: number;
    quantity?: number;
    user_notes?: string;
  }
) => Axios.post(`/user/add-to-cart/${productId}`, productOptions);

export const updateCartFn = (data: { cart_id: number; quantity?: number; user_notes?: string }) =>
  Axios.post("/user/update-cart?cart_temp_id=2021092401522393a1", data);

export const updateCartComment = (data: { comment: string }) =>
  Axios.post<APIResponse<[], BaseMeta>>("/user/cart-comment", data);

export const deleteCartFn = (data: { cart_id: number; variation_id?: number }) =>
  Axios.get(`/user/remove-from-cart/${data.cart_id}`, {
    params: data.variation_id && {
      variation_id: data.variation_id
    }
  });

export const getTermsAndConditions = () => Axios.get<APIResponse<{ terms: string }, BaseMeta>>("/terms");

export const checkoutOrder = (
  data: {
    address_id: number;
    payment_method: number;
    only_down_payment: 1 | 0;
    coupon_code?: string;
    [x: string]: any;
  },
  userToken: string
) =>
  Axios.post<APIResponse<OrderDetails, { iframe_link: string }>>("/user/checkout-order", data, {
    headers: {
      Authorization: "Bearer " + userToken
    }
  });

export const updateOrderProductQuantity = data => Axios.post(`/user/update-order-product-quantity`, data);

export const deleteOrderProduct = (data: { order_product_id: number }) =>
  Axios.post(`/user/delete-order-product/${data.order_product_id}`);

export const updateOrderSelectedAddress = (data: { address_id: number; order_number: string }) =>
  Axios.post<APIResponse<UserAddress, BaseMeta>>(`/user/order/update-address`, data);

export const updateOrderProductComment = (data: { order_product_id: number; comment: string }) =>
  Axios.post<APIResponse<[], BaseMeta>>(`/user/update-order-product-comment`, data);

export const updateOrderComment = (data: { order_number: number; comment: string }) =>
  Axios.post<APIResponse<[], BaseMeta>>("/user/order/update-comment", data);

export const orderVisaSuccess = (query: string, userToken: string) =>
  Axios.get<APIResponse<VisaSuccessResponse, VisaSuccessMeta>>(`/user/paymob/visa-success?${query}`, {
    headers: {
      Authorization: "Bearer " + userToken
    }
  });

export const payWithCard = (data: { order_number: string; only_down_payment: 1 | 0; payment_method?: number }) =>
  Axios.post<APIResponse<OrderDetails, { iframe_link: string }>>("/user/order/pay-visa-order", data);

export const updateOrderData = (data: { order_number: string; payment_method: number; only_down_payment: 1 | 0 }) =>
  Axios.post<APIResponse<OrderDetails, { iframe_link: string }>>("/user/order/update-payment-method", data);

// ====== NEW
export const addToCart = (
  variation_id: number,
  data: {
    quantity: number;
  }
) => Axios.post(`/user/add-to-cart/${variation_id}`, data);

export const toggleFavProduct = (data: { id: number }) => Axios.post("/user/toggle-fav-product", data);

export const getFreeShippingAreas = () => Axios.get("user/governorates?free_shipping=1");

export const _ValidatePromoCode = (data: CouponData) =>
  Axios.post<APIResponse<CouponResData, { message?: string }>>("/coupon/validate", data);

export const _CalculateShipping = (governorate_id: number, order_number?: number) =>
  Axios.post<APIResponse<{ shipping: number }, BaseMeta>>("/user/calc-shipping", { governorate_id, order_number });
