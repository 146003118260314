import { capitalize } from "@Utils/Strings";
import "firebase/analytics";
import firebase from "firebase/app";
import { Router } from "next/router";

const analytics = firebase.analytics;

const routeMap = {
  "/": "Landing Page",
  "/design": "Design Page",
  "/login": "Login Page",
  "/register": "Register Page",
  "/how": "How It Works",
  "/projects": "Curated Rooms",
  "/brands": "Brands",
  "/brands/[slug]": "Brands Details",
  "/favorites/[slug]": "Wishlist",
  "/profile/orders": "My Orders",
  "/profile/orders/[id]": "Order Details",
  "/profile/projects": "My Projects",
  "/profile/projects/[id]/overview": "Project Results",
  "/profile/account-details": "Account Details",
  "/profile/forgot-password": "Account Details",
  "/contact": "Help And Contact Us",
  "/return-policy": "Return Policy",
  "/blog": "Blog",
  "/blog/[slug]": "Blog Details",
  "/shop/[productId]": "Product Details",
  "/checkout": "Cart",
  "/style": "Style Quiz",
  "/style/result": "Style Quiz Results",
  "/project-quiz": "Project Quiz"
};

const getScreenName = (route: string, asPath: string, nonRouteEvent?: boolean): string => {
  if (route === "/projects/[projectSlug]/[roomSlug]") {
    const splittedPath = asPath.split("/");
    return capitalize(splittedPath[splittedPath.length - 1].replace(/-/g, " "));
  } else if (route === "/projects/[projectSlug]") {
    return capitalize(asPath.replace("/projects/", "").replace(/-/g, " "));
  } else if (nonRouteEvent && route.startsWith("/style/") && route !== "/style/[id]") {
    return routeMap["/style"];
  } else if (nonRouteEvent && route === "/style/[id]") {
    return routeMap["/style/result"];
  } else if (nonRouteEvent && route.startsWith("/project-quiz/")) {
    return routeMap["/project-quiz"];
  } else {
    return routeMap[route];
  }
};

const getEventName = (route: string, asPath: string): string =>
  "View_" + getScreenName(route, asPath).replace(/ /g, "_");

const getRouterEventOptions = (
  route: string,
  asPath: string,
  name?: string,
  moreOptions?: Record<string, string>
): Record<string, string> => {
  let options: Record<string, string> = {
    screen_name: getScreenName(route, asPath)
  };

  if (route === "/brands/[slug]") {
    options.brand_name = capitalize(name);
  }

  if (route === "/shop/[productId]" && name) {
    options.product_name = capitalize(name);
  }

  if (moreOptions) {
    options = {
      ...options,
      ...moreOptions
    };
  }

  return options;
};

const logRouterEvent = ({ route, asPath }: Router, name?: string) => {
  if (!name && route === "/shop/[productId]") return; // ! Handle it from The Page itself
  if (!name && route === "/brands/[slug]") return; // ! Handle it from The Page itself
  if (!getScreenName(route, asPath)) return;

  analytics().logEvent(getEventName(route, asPath), getRouterEventOptions(route, asPath, name));
};

export { firebase, analytics, getScreenName, logRouterEvent };
