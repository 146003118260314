import React from "react";
import styled from "styled-components";

const I = styled.i`
  font-size: inherit;
`;

export default function Icon({ icon, className, ...props }: { icon: string; className?: string; [x: string]: any }) {
  return <I className={`efreshli icon-${icon} ${className || ""}`} {...props} />;
}
